import { pedantMarketApi } from "../clients"
import type { SubscriptionPercent } from "../types"

const get = () => {
  return pedantMarketApi.get<{ data: SubscriptionPercent[] }>("/subscription-percents")
}

export const subscriptionPercents = {
  get
}
