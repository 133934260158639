import { cx } from "cva"

const wrapper = cx([
  "min-w-[728px]",
  "mr-[102px]",
  "flex",
  "justify-between"
])

const category = cx([
  "text-base/[130%]",
  "text-primary-on-color",
  "font-bold",
  "whitespace-nowrap"
])

const links = cx([
  "flex",
  "items-center",
  "text-base/[130%]",
  "text-secondary-on-color",
  "hover:text-primary-on-color",
  "font-normal",
  "duration-300"
])

const unavailableLink = cx([
  "opacity-60",
  "pointer-events-none"
])

export default {
  wrapper,
  category,
  links,
  unavailableLink
}
