import { cx } from "cva"

const root = cx([
  "w-full",
  "overflow-hidden"
])

const slider = cx([
  "animate-scroll-gallery",
  "flex",
  "w-full"
])

const list = cx([
  "flex",
  "left-[-12%]",
  "relative"
])

const listSmall = cx([
  "!left-[-15%]"
])

const image = cx([
  "min-w-[336px]",
  "min-h-[186px]",
  "max-w-[336px]",
  "max-h-[186px]",
  "object-cover",
  "rounded-xl",
  "m-1.5",
  "xl:min-w-[416px]",
  "xl:min-h-[230px]",
  "xl:max-w-[416px]",
  "xl:max-h-[230px]",
  "xl:m-2"
])

const imageSmall = cx([
  "!min-w-[249px]",
  "!min-h-[148px]",
  "!max-w-[249px]",
  "!max-h-[148px]",
  "xl:!min-w-[308px]",
  "xl:!min-h-[184px]",
  "xl:!max-w-[308px]",
  "xl:!max-h-[184px]"
])

export default {
  root,
  slider,
  list,
  listSmall,
  image,
  imageSmall
}
