import {createPinia} from "pinia"
import piniaPluginPersistedstate from "pinia-plugin-persistedstate"
import { PiniaSharedState } from "pinia-shared-state"

export const store = createPinia()
  .use(piniaPluginPersistedstate)
  .use(PiniaSharedState({
    // Enables the plugin for all stores. Defaults to true.
    enable: false,
    // If set to true this tab tries to immediately recover the shared state from another tab. Defaults to true.
    initialize: false,
    // Enforce a type. One of native, idb, localstorage or node. Defaults to native.
    type: "localstorage"
  }))
