import autocomplete from "autocompleter"
import api from "shared/api"
import { Ref, ref } from "vue"

export const initFullNameSuggestions = (fullName: Ref) => {
  const fullNameElement: HTMLInputElement | null = document.querySelector("#fullName")
  if (!fullNameElement) return

  autocomplete({
    minLength: 1,
    input: fullNameElement,
    className: "text-input-autocomplete",
    fetch: function(text, callback) {
      api.dadata.getFullName(text)
        .then((response) => {
          const suggestions = response.data.suggestions
          callback(suggestions.map(suggestion => ({
            label: suggestion.value,
            value: suggestion.value
          })))
        })
    },
    debounceWaitMs: 100,
    onSelect: (item: { label: string, value: string}) => {
      fullName.value = item.value
      fullNameElement.value = item.value
    }
  })
}

export const initAddressSuggestions = (region: Ref, city: Ref, street: Ref, house: Ref, flat: Ref, prefix: string, isRegistrationAddress?: Ref) => {
  const fields = {
    region: { id: `${prefix}AddressRegion`, ref: region },
    city: { id: `${prefix}AddressCity`, ref: city },
    street: { id: `${prefix}AddressStreet`, ref: street },
    house: { id: `${prefix}AddressHouse`, ref: house },
    flat: { id: `${prefix}AddressApartment`, ref: flat }
  } as Record<string, { id: string, ref: Ref}>

  const isFieldEntered = (currentKey: string, checkedKey: string) => currentKey !== checkedKey && fields[checkedKey].ref.value
  const getFieldValue = (currentKey: string, checkedKey: string) => isFieldEntered(currentKey, checkedKey) ? fields[checkedKey].ref.value : undefined

  Object.keys(fields).forEach(key => {
    const field = fields[key]
    const element: HTMLInputElement | null = document.querySelector(`#${field.id}`)
    const isSelectedValue = ref<Boolean>(false)

    if (element) {
      autocomplete({
        minLength: 1,
        input: element,
        className: "text-input-autocomplete",
        fetch: function(text, callback) {
          if (isSelectedValue.value) {
            isSelectedValue.value = false
            return
          }

          if (prefix === "residential" && isRegistrationAddress?.value) return

          api.dadata.getAddress(text, key, {
            "region": getFieldValue(key, "region"),
            "city": getFieldValue(key, "city"),
            "street": getFieldValue(key, "street"),
            "house": getFieldValue(key, "house"),
            "flat": getFieldValue(key, "flat")
          })
            .then((response) => {
              const suggestions = response.data.suggestions
              const uniqueSuggestions = new Set<string>(suggestions.map(suggestion => suggestion.data[key] as string))

              if (uniqueSuggestions.size === 1 && uniqueSuggestions.has(text)) return

              callback(Array.from(uniqueSuggestions).map(suggestion => ({
                label: suggestion,
                value: suggestion
              })))
            })
        },
        debounceWaitMs: 100,
        onSelect: (item: { label: string, value: string}) => {
          field.ref.value = item.value
          element.value = item.value
          isSelectedValue.value = true
        }
      })
    }
  })
}

