<script setup lang='ts'>
import { ref, watch } from "vue"
import { cx } from "cva"
import classes from "./classes"
import { InputText, Icon } from "../"
import { useRouter } from "vue-router"
import { ROUTE_NAMES } from "shared/constants"
import { YANDEX_METRIKA_GOALS, emitYandexMetrika } from "shared/lib"
import mixpanel from "mixpanel-browser"

const router = useRouter()

defineProps({
  modelValue: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    default: ""
  },
  iconAlign: {
    type: String,
    default: "right" // or "left"
  },
  closable: {
    type: Boolean,
    default: false
  }
})

const searchText = ref("")

const emits = defineEmits<{
  "update:modelValue": [payload: string];
  close: [payload: string];
}>()

const onClose = () => {
  searchText.value = ""
  emits("close", "")
}

watch(searchText, (newSearchText) => {
  emits("update:modelValue", newSearchText)
})

const search = () => {
  emitYandexMetrika(YANDEX_METRIKA_GOALS.POISK_HEADER)
  mixpanel.track("search", {
    query: searchText.value
  })

  router.push({
    name: ROUTE_NAMES.SEARCH,
    params: {
      searchQuery: searchText.value
    }
  })
}
</script>

<template>
  <form
    class="relative"
    @submit.prevent="search"
  >
    <InputText
      v-model="searchText"
      intent="primary"
      :type="'search'"
      :class-name="cx(classes.input, iconAlign === 'right' ? 'pr-[61px]' : 'pl-[40px]')"
      :minlength="4"
      :placeholder="placeholder"
    />
    <div :class="[classes.icon, iconAlign === 'right' ? 'right-[25px]' : 'left-[8px]']">
      <Icon
        name="common/search"
        width="22"
        height="22"
        class="text-ghost"
        @click="search"
      />
      <Icon
        v-if="closable"
        name="common/cross"
        width="24"
        height="24"
        class="text-ghost"
        @click="onClose"
      />
    </div>
  </form>
</template>
