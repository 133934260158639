const wrapper = [
  "grid",
  "grid-flow-col",
  "auto-cols-min",
  "gap-x-[5px]"
]

const icon = [
  "p-1",
  "rounded-lg",
  "text-primary-on-color",
  "duration-300",
  "hover:bg-icon-accent-hover"
]

export default {
  wrapper,
  icon
}
