const root = [
  "relative",
  "grid",
  "gap-5",
  "justify-center",
  "justify-items-center",
  "content-start",
  "self-start",
  "mb-6",
  "xl:gap-[7px]",
  "xl:sticky",
  "xl:grid-cols-[repeat(2,_auto)]",
  "xl:top-[150px]",
  "xl:justify-items-start",
  "xl:mb-0"
]

const wrapper = [
  "relative",
  "w-full"
]

const button = [
  "block",
  "my-0",
  "mx-auto"
]

const selectedMedia = [
  "w-full",
  "!h-[420px]",
  "object-cover",
  "rounded-xl",
  "overflow-hidden",
  "flex",
  "items-center",
  "xl:!h-[511px]"
]

const stoppers = [
  "absolute",
  "top-[37px]",
  "left-[12px]",
  "z-10",
  "xl:top-[40px]",
  "xl:left-[15px]"
]

const firstStopper = [
  "absolute",
  "top-[12px]",
  "left-[12px]",
  "z-10",
  "xl:top-[15px]",
  "xl:left-[15px]"
]

const icons = [
  "absolute",
  "top-[12px]",
  "right-[12px]",
  "flex",
  "flex-col",
  "z-10"
]

const pagination = [
  "hidden",
  "xl:flex",
  "xl:justify-center",
  "xl:w-full"
]

const slide = [
  "w-[56px]",
  "h-[56px]",
  "rounded-[7px]",
  "flex",
  "items-center",
  "justify-center",
  "cursor-pointer"
]

const slideActive = [
  "border-2",
  "border-solid",
  "border-icon-yellow"
]

const photo = [
  "w-full",
  "h-full",
  "p-[5px]",
  "object-cover",
  "rounded-lg"
]

const skeletonPanel = [
  "w-[56px]",
  "h-[511px]",
  "rounded-xl"
]

const skeletonImage = [
  "w-[382px]",
  "h-[511px]",
  "rounded-xl"
]

const skeletonMobileImage = [
  "w-full",
  "h-[420px]",
  "rounded-xl"
]

export default {
  root,
  wrapper,
  button,
  selectedMedia,
  stoppers,
  firstStopper,
  icons,
  pagination,
  slide,
  slideActive,
  photo,
  skeletonPanel,
  skeletonImage,
  skeletonMobileImage
}
