import { ROUTE_NAMES } from "shared/constants"

export default [
  {
    name: "Продать смартфон",
    to: ROUTE_NAMES.TRADE_IN,
    reachGoal: () => {}
  },
  {
    name: "Ещё",
    items: [
      {
        name: "О подписке",
        to: ROUTE_NAMES.SUBSCRIPTION_DESCRIPTION,
        reachGoal: () => {}
      },
      {
        name: "Как оплатить и получить",
        to: ROUTE_NAMES.PAYMENT,
        reachGoal: () => {}
      },
      {
        name: "Гарантия",
        to: ROUTE_NAMES.GUARANTEE,
        reachGoal: () => {}
      },
      {
        name: "Возврат",
        to: ROUTE_NAMES.RETURN,
        reachGoal: () => {}
      },
      {
        name: "Адреса СЦ для гарантийного ремонта",
        to: ROUTE_NAMES.GARANTIA_SERVICE,
        reachGoal: () => {}
      },
      {
        name: "О компании",
        to: ROUTE_NAMES.COMPANY,
        reachGoal: () => {}
      },
      {
        name: "Контакты",
        to: ROUTE_NAMES.CONTACTS,
        reachGoal: () => {}
      },
    ]
  }
]
