<script setup lang="ts">
import classes from "./classes"
import { Button, Icon } from "shared/ui"
import {
  YANDEX_METRIKA_GOALS,
  emitYandexMetrika,
  emitMixpanelEventBannerAboveHeaderClick,
  getTelegramLink
} from "shared/lib"
import { useUtmStore } from "stores"
import { computed } from "vue"
import { useBreakpoints } from "@vueuse/core"

const utmStore = useUtmStore()
const breakpoints = useBreakpoints({ xl: 1200 })
const xlAndLarger  = breakpoints.greaterOrEqual("xl")

const telegramLink = computed(() => {
  if (!utmStore.utmTags) return ""

  const params = new URLSearchParams({
    start: `pm_${ utmStore.utmTags["utm_campaign"]}_${xlAndLarger.value ? "desktop" : "mobile"}`
  })

  return `${getTelegramLink(2)}?${params.toString()}`
})

const clickOnBanner = () => {
  emitYandexMetrika(YANDEX_METRIKA_GOALS.BANNER_ABOVE_HEADER_CLICK)
  emitMixpanelEventBannerAboveHeaderClick()
  window.open(telegramLink.value, "_blank")
}
</script>

<template>
  <div
    :class="classes.root"
    @click="clickOnBanner"
  >
    <div :class="classes.wrapper">
      <div :class="classes.inner">
        <ul :class="classes.ul">
          <li :class="classes.li">
            <Icon
              name="common/point"
              width="4"
              height="5"
              class-name="text-icon-yellow"
            />
            Быстро
          </li>
          <li :class="classes.li">
            <Icon
              name="common/point"
              width="4"
              height="5"
              class-name="text-icon-yellow"
            />
            Дорого
          </li>
          <li :class="classes.li">
            <Icon
              name="common/point"
              width="4"
              height="5"
              class-name="text-icon-yellow"
            />
            Без коробки и документов
          </li>
        </ul>
        <div :class="classes.buttonWrapper">
          <Button
            intent="base-primary"
            size="middle"
            :class="classes.button"
            style="border-radius: 8px;"
          >
            Продать смартфон
          </Button>
          <div :class="classes.iconWrapper">
            <Icon
              name="socials/telegram-white"
              width="19"
              height="16"
              class-name="ml-1"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
