const magnifier = [
  "absolute",
  "w-[200px]",
  "h-[200px]",
  "border",
  "border-solid",
  "border-border-accent",
  "bg-bg-main-secondary",
  "opacity-30",
  "pointer-events-none"
]

const magnifiedImage = [
  "absolute",
  "top-0",
  "left-[calc(100%_+_20px)]",
  "w-[500px]",
  "h-[500px]",
  "border-2",
  "border-solid",
  "border-border-accent",
  "bg-no-repeat",
  "z-[1000]",
  "pointer-events-none"
]

export default {
  magnifier,
  magnifiedImage
}
