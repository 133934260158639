<script setup lang="ts">
import { shallowRef, computed, watch } from "vue"
import classes from "./classes"
import { storeToRefs } from "pinia"
import { useServiceCentersStore, useCartStore } from "stores"
import { type ServiceCenter } from "shared/api"
import { YANDEX_METRIKA_GOALS, emitMixpanelEventCartBranchSelected, emitYandexMetrika } from "shared/lib"
import { YandexMap, YandexMapDefaultSchemeLayer, YandexMapDefaultFeaturesLayer, YandexMapMarker } from "vue-yandex-maps"
import type { YMapMarkerProps, LngLatBounds, YMap, LngLat } from "@yandex/ymaps3-types"

const props = defineProps({
  hasModal: {
    type: Boolean,
    default: false,
    required: false
  },
  hasEvent: {
    type: Boolean,
    default: false,
    required: false
  }
})

const serviceCentersStore = useServiceCentersStore()
const { list: serviceCenters, current: currentServiceCenter } = storeToRefs(serviceCentersStore)

const cartStore = useCartStore()

const map = shallowRef<YMap | null>(null)

const markers = computed(() => {
  const markers: YMapMarkerProps[] = []

  serviceCenters.value.forEach(serviceCenter => markers.push({
    coordinates: [Number(serviceCenter.longitude), Number(serviceCenter.latitude)] as LngLat,
    source: `/images/map-marker${!props.hasModal && serviceCenter === currentServiceCenter.value ? "-selected" : ""}.png`
  }))

  return markers
})

const emit = defineEmits(["serviceCenterSelected"])

const selectServiceCenter = (serviceCenter: ServiceCenter) => {
  if (props.hasModal) {
    emit("serviceCenterSelected", serviceCenter)
  } else {
    serviceCentersStore.current = serviceCenter
  }
}

const triggerAnalyticGoals = () => {
  if (props.hasEvent && serviceCentersStore.current && cartStore.device) {
    emitMixpanelEventCartBranchSelected(serviceCentersStore.current, cartStore.device, cartStore.isPromocodeApplied)
    emitYandexMetrika(YANDEX_METRIKA_GOALS.VIBOR_SC)
  }
}

const getAllMarkersBounds = () => {
  const {
    minLongitude,
    minLatitude,
    maxLongitude,
    maxLatitude
  } = markers.value.map((marker) => marker.coordinates)
    .reduce(
      (acc, [longitude, latitude]) => ({
        minLongitude: Math.min(acc.minLongitude, longitude),
        minLatitude: Math.min(acc.minLatitude, latitude),
        maxLongitude: Math.max(acc.maxLongitude, longitude),
        maxLatitude: Math.max(acc.maxLatitude, latitude)
      }),
      {
        minLongitude: Infinity,
        minLatitude: Infinity,
        maxLongitude: -Infinity,
        maxLatitude: -Infinity
      }
    )

  return [[minLongitude, maxLatitude], [maxLongitude, minLatitude]] as LngLatBounds
}

const recenter = () => {
  if (!map.value) return

  map.value.setLocation({ bounds: getAllMarkersBounds() })
  map.value!.setLocation({ zoom: 9 })
}

watch([map, serviceCenters], () => recenter())
</script>

<template>
  <yandex-map
    v-model="map"
    :settings="{
      location: {
        center: [55.93634, 53.62843],
        zoom: 16,
      },
    }"
    width="100%"
    height="360px"
  >
    <yandex-map-default-scheme-layer />
    <yandex-map-default-features-layer />

    <yandex-map-marker
      v-for="(marker, index) in markers"
      :key="index"
      :settings="{ coordinates: marker.coordinates }"
    >
      <img
        :alt="serviceCenters[index].full_address"
        :src="marker.source"
        :class="classes.marker"
        @click="selectServiceCenter(serviceCenters[index]); triggerAnalyticGoals()"
      >
    </yandex-map-marker>
  </yandex-map>
</template>
