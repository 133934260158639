<script setup lang="ts">
import classes from "./classes"
import { ref } from "vue"

const props = defineProps({
  imageUrl: {
    type: String,
    default: "",
    required: true
  },
  imageClassName: {
    type: String,
    default: "",
    required: true
  }
})

const isShowMagnified = ref(false)
const magnifierBoxPositionX = ref("")
const magnifierBoxPositionY = ref("")
const magnifiedImageZoom = ref(2.5)
const magnifiedImageStyles = ref({})

// refs ui elements
const magnifierBox = ref<HTMLDivElement | null>(null)
const magnifiedImage = ref<HTMLDivElement | null>(null)

const showMagnifiedImage = (event: MouseEvent) => {
  isShowMagnified.value = true

  if (!magnifiedImage.value || !magnifierBox.value) return

  // Calculate the position of the cursor relative to the magnifier container
  const containerRect = magnifiedImage.value.getBoundingClientRect()
  const mouseX = event.clientX - containerRect.left
  const mouseY = event.clientY - containerRect.top

  // Calculate the position of the magnifier box
  const magnifierBoxSize = magnifierBox.value.offsetWidth
  const magnifierBoxLeft = mouseX - magnifierBoxSize / 2
  const magnifierBoxTop = mouseY - magnifierBoxSize / 2

  // Adjust the position of the magnifier box if it would go outside of the container
  const maxLeft = containerRect.width - magnifierBoxSize
  const maxTop = containerRect.height - magnifierBoxSize
  magnifierBoxPositionX.value = `${Math.min(Math.max(0, magnifierBoxLeft), maxLeft)}px`
  magnifierBoxPositionY.value = `${Math.min(Math.max(0, magnifierBoxTop), maxTop)}px`

  const magnifierImagePositionX = `${-Math.min(Math.max(0, magnifierBoxLeft), maxLeft) * magnifiedImageZoom.value}px`
  const magnifierImagePositionY = `${-Math.min(Math.max(0, magnifierBoxTop), maxTop) * magnifiedImageZoom.value}px`

  magnifiedImageStyles.value = {
    backgroundImage: `url(${props.imageUrl})`,
    backgroundPositionY: magnifierImagePositionY,
    backgroundPositionX: magnifierImagePositionX,
    backgroundSize: `calc(${containerRect.width}px * ${magnifiedImageZoom.value}) calc(${containerRect.height}px * ${magnifiedImageZoom.value})`
  }
}
</script>

<template>
  <div>
    <img
      ref="magnifiedImage"
      :class="props.imageClassName"
      :src="props.imageUrl"
      loading="lazy"
      alt="Увеличенное изображение"
      @mousemove="showMagnifiedImage"
      @mouseleave="isShowMagnified = false"
    >
    <div
      v-if="isShowMagnified"
      ref="magnifierBox"
      :class="classes.magnifier"
      :style="{ top: magnifierBoxPositionY, left: magnifierBoxPositionX }"
    />
    <div
      v-if="isShowMagnified"
      :class="classes.magnifiedImage"
      :style="magnifiedImageStyles"
    />
  </div>
</template>
