import { cx } from "cva"

const wrapper = cx([
  "mb-[24px]",
  "text-[14px]/[21px]",
  "xl:text-[16px]/[24px]"
])

const author = cx([
  "flex",
  "mb-0.5"
])

const authorName = cx([
  "font-semibold",
  "text-primary",
  "leading-none"
])

const row = cx([
  "grid",
  "grid-rows-[repeat(2,auto)]",
  "gap-y-1",
  "xl:gap-y-2"
])

const text = cx([
  "mb-[17px]",
  "xl:mb-[19px]"
])

const device = cx([
  "text-[11px]/[12px]",
  "text-ghost"
])

const actions = cx([
  "flex",
  "items-center",
  "text-primary",
  "justify-end",
  "xl:justify-start"
])

const date = cx([
  "text-[10px]",
  "text-ghost",
  "xl:text-[11px]"
])

const like = cx([
  "flex",
  "justify-around",
  "items-center",
  "bg-bg-main-secondary",
  "rounded-lg",
  "py-1",
  "px-2.5",
  "transition",
  "duration-300",
  "text-[10px]",
  "text-ghost",
  "fill-[rgb(189,_189,_189)]",
  "xl:text-[11px]",
  "group"
])

const likeIcon = cx([
  "group-hover:fill-[rgb(255,_131,_131)]"
])

const likeText = cx([
  "mr-[5px]"
])

const media = cx([
  "mb-4"
])

const authorContent = cx([
  "mb-2.5",
  "w-fit",
  "grid",
  "grid-cols-[auto_1fr]",
  "gap-x-4"
])

const userInitials = cx([
  "w-[49px]",
  "h-[49px]",
  "bg-bg-main-thirdly",
  "rounded-[50%]",
  "flex",
  "justify-center",
  "items-center",
  "font-bold",
  "text-accent",
  "xl:w-[60px]",
  "xl:h-[60px]"
])

const source = cx([
  "text-[11px]",
  "grid",
  "grid-cols-[auto_1fr]",
  "gap-x-4",
  "items-center"
])

const sourceCol = cx([
  "flex",
  "items-center",
  "text-primary"
])

const sourceColI = cx([
  "text-yellow",
  "ml-[5px]"
])

const sourceImage = cx([
  "ml-[3px]",
  "w-auto",
  "h-[11px]"
])

export default {
  wrapper,
  author,
  authorName,
  row,
  text,
  device,
  actions,
  date,
  like,
  likeIcon,
  likeText,
  media,
  authorContent,
  userInitials,
  source,
  sourceCol,
  sourceColI,
  sourceImage
}
