import { pedantMarketApi } from "../clients"
import { PaymentRedirect } from "../types"

const get = (slug: string, isAdvance: boolean) => {
  return pedantMarketApi.get<PaymentRedirect>(`/subscription-plans/${slug}`, {
    params: {
      advancePayment: isAdvance,
      earlyCancel: false,
      withoutFine: true
    }
  })
}

export const paymentRedirect = {
  get
}
