<script setup lang="ts">
import { computed } from "vue"
import { MainMenuDropdown } from ".."
import { Button } from "shared/ui"
import { MODEL_GROUP_NAME, ROUTE_NAMES } from "shared/constants"
import classes from "./classes"
import otherLinks from "./links"
import { useModelGroupsStore } from "stores"
import { storeToRefs } from "pinia"
import { useBreakpoints } from "@vueuse/core"
import { YANDEX_METRIKA_GOALS, emitYandexMetrika } from "shared/lib"

const modelGroupsStore = useModelGroupsStore()
const { list: categories } = storeToRefs(modelGroupsStore)

const breakpoints = useBreakpoints({ xl: 1200 })
const xlAndLarger  = breakpoints.greaterOrEqual("xl")

const desktopLinks = computed(() => {
  if (!xlAndLarger.value) return []
  const catalogItems = categories.value.filter((s) => s.models.length).map(category => {
    return {
      name: category.name,
      to: category.url === "/phone-i" ? category.url + "?c_subscription=1" : category.url,
      items: [],
      reachGoal: () => {
        emitYandexMetrika(YANDEX_METRIKA_GOALS.VKATALOG_KATEGORII_HEADER)

        if (category.name === MODEL_GROUP_NAME.IPHONE) emitYandexMetrika(YANDEX_METRIKA_GOALS.CATALOG_IPHONE)
        else if (category.name === MODEL_GROUP_NAME.SAMSUNG) emitYandexMetrika(YANDEX_METRIKA_GOALS.CATALOG_SAMSUNG_IZ_SHAPKI)
        else if (category.name === MODEL_GROUP_NAME.HUAWEI) emitYandexMetrika(YANDEX_METRIKA_GOALS.CATALOG_HUAWEI_IZ_SHAPKI)
        else if (category.name === MODEL_GROUP_NAME.XIAOMI) emitYandexMetrika(YANDEX_METRIKA_GOALS.CATALOG_XIAOMI_IZ_SHAPKI)
      }
    }
  })

  return [
    {
      name: "Каталог",
      to: ROUTE_NAMES.CATALOG_CATEGORIES,
      items: [],
      reachGoal: () => emitYandexMetrika(YANDEX_METRIKA_GOALS.VESKATALOG_HEADER)
    },
    ...catalogItems,
    ...otherLinks
  ]
})

const mobileLinks = computed(() => {
  if (xlAndLarger.value) return []
  const catalogItems = categories.value.map(category => {
    return {
      name: category.name,
      to: category.url === "/phone-i" ? category.url + "?c_subscription=1" : category.url,
      disabled: !category.models.length,
      reachGoal: () => {
        emitYandexMetrika(YANDEX_METRIKA_GOALS.VKATALOG_KATEGORII_HEADER)

        if (category.name === MODEL_GROUP_NAME.IPHONE) emitYandexMetrika(YANDEX_METRIKA_GOALS.CATALOG_IPHONE)
        else if (category.name === MODEL_GROUP_NAME.SAMSUNG) emitYandexMetrika(YANDEX_METRIKA_GOALS.CATALOG_SAMSUNG_IZ_SHAPKI)
        else if (category.name === MODEL_GROUP_NAME.HUAWEI) emitYandexMetrika(YANDEX_METRIKA_GOALS.CATALOG_HUAWEI_IZ_SHAPKI)
        else if (category.name === MODEL_GROUP_NAME.XIAOMI) emitYandexMetrika(YANDEX_METRIKA_GOALS.CATALOG_XIAOMI_IZ_SHAPKI)
      }
    }
  })

  catalogItems.push({
    name: "Весь каталог",
    to: ROUTE_NAMES.CATALOG_CATEGORIES,
    disabled: false,
    reachGoal: () => emitYandexMetrika(YANDEX_METRIKA_GOALS.VESKATALOG_HEADER)
  })

  return [
    {
      name: "Каталог",
      items: catalogItems,
      reachGoal: () => {}
    },
    {
      name: "О подписке",
      to: ROUTE_NAMES.SUBSCRIPTION_DESCRIPTION,
      reachGoal: () => emitYandexMetrika(YANDEX_METRIKA_GOALS.PODPISKA_GLAVNAYA_CTA_V3)
    },
    {
      name: "Продать смартфон",
      to: ROUTE_NAMES.TRADE_IN,
      reachGoal: () => {}
    }
  ]

})

const links = computed(() => xlAndLarger.value ? desktopLinks.value : mobileLinks.value)
</script>

<template>
  <nav :class="classes.navigationStyles">
    <ul :class="classes.wrapperStyles">
      <li
        v-for="(link, index) in links"
        :key="index"
        :class="classes.navigationItemStyles"
      >
        <Button
          v-if="link.to"
          type="router-link"
          :url="Object.values(ROUTE_NAMES).includes(link.to) ? { name: link.to } : link.to"
          @click="link.reachGoal()"
        >
          {{ link.name }}
        </Button>
        <MainMenuDropdown
          v-else-if="link.items"
          :name="link.name"
          :items="link.items"
          :catalog-icon="link.name === 'Каталог'"
          :is-right-position="index === links.length - 1"
          :class="classes.navigationItemDropdownStyles"
        />
      </li>
    </ul>
  </nav>
</template>
