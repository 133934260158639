<script setup lang="ts">
import classes from "./classes"
import { ref, shallowRef, PropType, onMounted, computed } from "vue"
import { Device } from "shared/api"
import { checkIsNew, loadDeviceImage, skeletonStyles, numberSpace } from "shared/lib"
import { useBreakpoints } from "@vueuse/core"
import { Button, Icon, Battery } from "shared/ui"
import { useCartStore, useSubscriptionStore } from "stores"
import { useRouter } from "vue-router"
import { EMIT_MODAL_FUNCTION_NAMES, ROUTE_NAMES } from "shared/constants"
import { SubscriptionPeriods, SubscriptionButton } from "widgets"
import { storeToRefs } from "pinia"

const breakpoints = useBreakpoints({ xl: 1200 })
const xlAndLarger  = breakpoints.greaterOrEqual("xl")

const router = useRouter()
const cartStore = useCartStore()
const subscriptionStore = useSubscriptionStore()

const { monthlyPayment } = storeToRefs(subscriptionStore)

const props = defineProps({
  device: {
    type: Object as PropType<Device>,
    default () { return {} },
    required: true
  },
  isShowRemoveButton: {
    type: Boolean,
    default: true,
    required: false
  },
  hasSubscription: {
    type: Boolean,
    default: false,
    required: false
  },
  hasSubscriptionTabs: {
    type: Boolean,
    default: false,
    required: false
  },
  hasPrice: {
    type: Boolean,
    default: true,
    required: false
  }
})

const abTest = shallowRef(false)
const isLoadingImage = ref(true)
const imageSrc = ref("")
const isNewDevice = computed(() => checkIsNew(props.device))
const deviceFullName = computed(() => `${props.device.FullName}${!isNewDevice.value ? ", б/у" : "" }`)

const removeFromCart = () => {
  cartStore.removeDeviceFromCart()
  router.replace({ name: ROUTE_NAMES.CART })
}

onMounted(() => loadDeviceImage(props.device, isLoadingImage, imageSrc))

const emit = defineEmits([
  EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SUBSCRIPTION_HOW_WORKS
])
</script>

<template>
  <div :class="[classes.wrapper, abTest ? classes.wrapperv2 : '']">
    <div
      v-if="isLoadingImage"
      :class="[classes.image, skeletonStyles]"
    />
    <img
      v-else
      :class="classes.image"
      :src="imageSrc"
      :width="xlAndLarger ? 214 : 130"
      :height="xlAndLarger ? 286 : 160"
      :alt="deviceFullName"
    >
    <div :class="classes.content">
      <div :class="classes.header">
        <div>
          <div :class="classes.deviceName">
            {{ deviceFullName }}
          </div>
        </div>
        <Button
          v-if="isShowRemoveButton"
          :class="classes.trash"
          @click="removeFromCart"
        >
          <Icon
            name="common/trash"
            width="19"
            height="22"
          />
        </Button>
      </div>
      <div :class="[classes.features, abTest ? classes.featuresv2 : '']">
        <div>
          Состояние: {{ device.ModelCondition.Name }}
        </div>
        <div>
          Аккумулятор:
          <Battery :percent="device.BatteryVolume" />
        </div>
      </div>
      <div
        v-if="hasSubscriptionTabs && monthlyPayment"
        :class="[classes.price, 'xl:hidden', { 'xl:hidden': hasSubscription}]"
      >
        <div :class="classes.priceTitle">
          Всего
        </div>
        <div :class="classes.pricePayment">
          {{ numberSpace(monthlyPayment) }}
          <span :class="classes.pricePaymentSpan">₽/мес</span>
        </div>
      </div>
      <SubscriptionPeriods
        v-if="hasSubscriptionTabs"
        :class="classes.periods"
        title="Выберите удобный период"
      />
      <div
        v-if="hasSubscriptionTabs && monthlyPayment"
        :class="[classes.price, 'hidden xl:grid', hasSubscription ? 'xl:hidden' : '']"
      >
        <div :class="classes.priceTitle">
          Всего
        </div>
        <div :class="classes.pricePayment">
          {{ numberSpace(monthlyPayment) }}
          <span :class="classes.pricePaymentSpan">₽/мес</span>
        </div>
      </div>
      <div
        v-if="hasSubscription && monthlyPayment"
        :class="[classes.price, 'hidden xl:grid']"
      >
        <div :class="classes.priceTitle">
          Всего
        </div>
        <div :class="classes.pricePayment">
          {{ numberSpace(monthlyPayment) }}
          <span :class="classes.pricePaymentSpan">₽/мес</span>
        </div>
      </div>
      <SubscriptionButton
        v-if="hasSubscription"
        :class="classes.button"
        @open-modal-subscription-how-works="emit(EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SUBSCRIPTION_HOW_WORKS)"
      />
      <div
        v-if="!hasSubscriptionTabs && hasPrice"
        :class="classes.actualPrice"
      >
        {{ numberSpace(device.Price) }} ₽
      </div>
    </div>
  </div>
</template>
