import { pedantMarketApi } from "../clients"
import type { Model, ModelsListParams } from "../types"

const get = ({ group, model }: ModelsListParams) => {
  return pedantMarketApi.get<Model>(`/models/${group}/${model}`)
}

const getList = (group: string) => {
  return pedantMarketApi.get<Model[]>(`/models/${group}`)
}

export const models = {
  get,
  getList
}
