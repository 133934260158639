import { pedantMarketApi } from "../clients"
import type { Paginated, Device, DeviceListParams } from "../types"

const getList = (params: DeviceListParams) => {
  return pedantMarketApi.get<Paginated<Device>>("/devices", {
    params: {
      ...params
    }
  })
}

const getItem = (deviceId: string) => {
  return pedantMarketApi.get<Device>(`/devices/${deviceId}`)
}

export const devices = {
  getList,
  getItem
}
