import { pedantMarketApi } from "../clients"
import type { SalesHits, SalesHitsParams } from "../types"

const getList = (params: SalesHitsParams) => {
  return pedantMarketApi.get<SalesHits[]>("/models", {
    params: {
      ...params
    }
  })
}

export const salesHits = {
  getList
}
