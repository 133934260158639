<script setup lang="ts">
import classes from "./classes"
import { cx } from "cva"
import { computed, ref } from "vue"
import { Button, Icon } from ".."
import { useCartStore } from "stores"
import { storeToRefs } from "pinia"

const cartStore = useCartStore()
const { promoCode } = storeToRefs(cartStore)

const inputPromoCode = ref("")
const sentInputPromoCode = ref("")
const inputFocused = ref(false)

const isFormInvalid = computed(() => !promoCode.value.valid &&
  !promoCode.value.loading &&
  !inputFocused.value &&
  sentInputPromoCode.value &&
  sentInputPromoCode.value === inputPromoCode.value)
</script>

<template>
  <div>
    <div :class="classes.inner">
      <div>
        <input
          id="promocode"
          v-model="inputPromoCode"
          type="text"
          :class="[classes.input, promoCode.valid ? classes.inputValid : '', isFormInvalid ? classes.inputInvalid : '']"
          :disabled="promoCode.valid || promoCode.loading"
          placeholder="Промокод"
          aria-label="Промокод"
          autocomplete="off"
          @input="() => (inputPromoCode = inputPromoCode.toUpperCase())"
          @focus="inputFocused = true"
          @blur="inputFocused = false"
        >
      </div>
      <Button
        :class="[classes.button, promoCode.valid ? classes.buttonValid : '', inputFocused ? classes.buttonFocus : '', isFormInvalid ? classes.buttonInvalid : '']"
        :disabled="promoCode.valid || promoCode.loading"
        @click="cartStore.applyPromoCode(inputPromoCode); sentInputPromoCode = inputPromoCode"
      >
        <Icon
          v-if="promoCode.loading"
          name="common/loader"
          width="16"
          height="16"
          class-name="animate-spin-left"
        />
        <Icon
          v-else-if="isFormInvalid"
          name="common/warning"
          width="14"
          height="14"
        />
        <Icon
          v-else
          name="common/check"
          width="16"
          height="12"
          :class-name="cx([classes.buttonIcon, promoCode.valid ? classes.buttonValidIcon : ''])"
        />
      </Button>
    </div>
    <div
      v-if="isFormInvalid"
      :class="classes.invalidNotice"
    >
      Такого промокода нет
    </div>
  </div>
</template>
