const wrapper = [
  "flex",
  "mb-10"
]

const wrapperv2 = "!mb-20"

const image = [
  "w-[104px]",
  "h-[138px]",
  "rounded-xl",
  "mr-3",
  "xl:w-[230px]",
  "xl:h-[309px]",
  "xl:mr-6",
  "object-contain",
  "object-top"
]

const content = "flex-1"

const header = [
  "flex",
  "justify-between",
  "mb-1.5",
  "xl:mr-[15px]",
  "xl:mb-4"
]

const deviceName = [
  "font-bold",
  "text-primary",
  "leading-[18px]",
  "xl:text-[36px]/[43px]",
  "break-normal"
]

const trash =  [
  "transition",
  "duration-300",
  "hover:opacity-70"
]

const features = [
  "text-[12px]",
  "mb-3.5",
  "xl:grid",
  "xl:grid-rows-[repeat(2,_auto)]",
  "xl:grid-cols-[repeat(2,_max-content)]",
  "xl:grid-rows-none",
  "xl:gap-x-10",
  "xl:text-[16px]",
  "xl:mb-5"
]

const featuresv2 = "xl:!mb-[46px]"

const price = [
  "grid",
  "grid-rows-[repeat(2,_auto)]",
  "xl:grid-rows-[auto]",
  "xl:grid-cols-[repeat(2,_min-content)]",
  "xl:whitespace-nowrap",
  "xl:items-center",
  "xl:gap-[7px]",
  "xl:text-[24px]/[31px]",
  "xl:my-4"
]

const pricev2 = "xl:!mt-[25px]"

const priceTitle = [
  "text-[16px]/[11px]",
  "font-bold",
  "text-primary",
  "xl:text-[24px]"
]

const pricePayment = [
  "text-[32px]/[48px]",
  "font-bold",
  "text-primary",
  "xl:text-[24px]"
]

const pricePaymentSpan = "text-[24px]"

const periods = [
  "hidden",
  "xl:block"
]

const actualPrice = [
  "text-[16px]/[120%]",
  "font-bold",
  "text-primary",
  "mt-2",
  "xl:text-[24px]",
  "xl:mt-4"
]

const button = [
  "mt-4",
  "hidden",
  "xl:flex"
]

export default {
  wrapper,
  wrapperv2,
  image,
  content,
  header,
  deviceName,
  trash,
  features,
  featuresv2,
  price,
  pricev2,
  priceTitle,
  pricePayment,
  pricePaymentSpan,
  periods,
  actualPrice,
  button
}
