const wrapper = [
  "w-[100%]",
  "h-[100%]",
  "max-w-[100%]",
  "min-h-[360px]"
]

const map = [
  "rounded-xl",
  "sm:my-0",
  "sm:-mx-1.5"
]

const marker = [
  "cursor-pointer",
  "!max-w-[unset]",
  "w-[34px]",
  "h-[45px]"
]

export default {
  wrapper,
  map,
  marker
}
