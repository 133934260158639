const input = [
  "min-w-full",
  "min-h-[44px]"
]

const icon = [
  "absolute",
  "top-2.5",
  "!text-icon-disable",
  "cursor-pointer"
]

export default {
  input,
  icon
}
