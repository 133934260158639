<script setup lang="ts">
import classes from "./classes"
import { cx } from "cva"
import { computed, PropType } from "vue"
import { Device } from "shared/api"
import { Icon, Button } from "shared/ui"
import { linkStyles, declension } from "shared/lib"
import { storeToRefs } from "pinia"
import { useCitiesStore } from "stores"
import { DEFAULT_CURRENT_CITY, EMIT_MODAL_FUNCTION_NAMES } from "shared/constants"
import { CatalogDeviceSubscriptionVideo } from "widgets"

const props = defineProps({
  device: {
    type: Object as PropType<Device>,
    default () { return {} },
    required: true
  }
})

const emit = defineEmits([
  EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_CITIES,
  EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_SERVICE_CENTERS
])

const citiesStore = useCitiesStore()
const { current: currentCity } = storeToRefs(citiesStore)

const deliveryTime = computed(() => {
  if (props.device?.IsOnDemand) {
    return "от 5 дней"
  } else if (currentCity.value?.name === DEFAULT_CURRENT_CITY) {
    return "от 1 дня"
  } else {
    return "от 3-х дней"
  }
})
</script>

<template>
  <div
    v-if="currentCity"
    :class="classes.wrapper"
  >
    <div :class="classes.location">
      <div :class="classes.title">
        Самовывоз
      </div>
      <div :class="classes.locationCity">
        <Icon
          name="common/location"
          width="14"
          height="15"
          :class-name="cx(classes.locationIcon)"
        />
        <Button
          :class="[linkStyles, classes.button, 'mr-1']"
          @click="emit(EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_CITIES)"
        >
          {{ currentCity.name }}
        </Button>
        <Button
          v-if="currentCity.services_count > 1"
          :class="[linkStyles, classes.button, '!font-normal']"
          @click="emit(EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_SERVICE_CENTERS)"
        >
          {{ currentCity.services_count }}
          {{ declension(currentCity.services_count, ["филиал", "филиала", "филиалов"]) }}
        </Button>
      </div>
      <div :class="classes.locationText">
        <b>Бесплатно</b>, доставка {{ deliveryTime }}
      </div>
    </div>
    <CatalogDeviceSubscriptionVideo
      :device="device"
      class="xl:hidden"
    />
  </div>
</template>
