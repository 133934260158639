import { cx } from "cva"

const base = cx([
  "flex",
  "items-center",
  "border-2",
  "border-solid",
  "border-border-primary",
  "rounded-lg",
  "overflow-hidden"
])

const baseFocus = cx([
  "!border-2",
  "!border-solid",
  "!border-border-active"
])

const resetInput = cx([
  "border-none",
  "outline-none",
  "bg-none",
  "bg-transparent",
  "shadow-none"
])

const inputGroup = cx([
  "relative",
  "w-1/2",
  "flex",
  "items-center",
  "px-[16px]",
  "py-[14px]"
])

const input = cx([
  "w-full",
  "text-ellipsis",
  "text-[16px]",
  "leading-none",
  "bg-snow-white-main",
  "text-primary"
])

const text = cx([
  "min-w-fit",
  "left-[21px]",
  "leading-none",
  "text-ghost",
  "mr-[8px]"
])

export default {
  base,
  baseFocus,
  resetInput,
  inputGroup,
  input,
  text
}
