import { cx } from "cva"

const wrapper = [
  "flex",
  "items-center",
  "h-[85px]",
  "py-4",
  "px-6",
  "my-5",
  "mx-0",
  "rounded-xl",
  "text-sm",
  "font-semibold",
  "text-primary",
  "bg-sun-yellow-200",
  "xl:h-[74px]",
  "xl:py-0",
  "xl:px-[26px]",
  "xl:text-base"
]

const img = cx([
  "text-icon-yellow",
  "mr-4"
])

export default {
  wrapper,
  img
}
