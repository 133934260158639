import { acceptHMRUpdate, defineStore } from "pinia"
import { ref } from "vue"

import api, { type SeoContent } from "shared/api"

export const useSeoContentStore = defineStore("seo-content", () => {
  const list = ref<SeoContent[]>([])

  function getList(url?: string, model_id?: string) {
    api.seo.get(url, model_id)
      .then(({ data }) => { list.value = data })
      .catch(() => { list.value = [] })
  }

  return { list, getList }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSeoContentStore, import.meta.hot))
}
