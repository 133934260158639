<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue"
import classes from "./classes"
import { useCitiesStore } from "stores"
import { storeToRefs } from "pinia"
import { DropdownSelect, TabList } from "shared/ui"
import { DropdownSelectOption } from "shared/lib"
import { ServiceCenterSelection, Map } from "widgets"
import { EMIT_MODAL_FUNCTION_NAMES } from "shared/constants"

const citiesStore = useCitiesStore()
const { current: currentCity, list: cities } = storeToRefs(citiesStore)

const dropdownCurrentCityId = ref<string | null>(null)
const citiesOptions = computed(() => cities.value.map(city => <DropdownSelectOption>{ name: city.name, value: city.id }))

onMounted(() => {
  // init dropdownCurrentCityId
  if (currentCity.value?.id) {
    dropdownCurrentCityId.value = currentCity.value.id
  }
})

// change currentCity
watch(dropdownCurrentCityId, (newDropdownCurrentCityId, oldDropdownCurrentCityId) => {
  if (oldDropdownCurrentCityId !== newDropdownCurrentCityId) {
    const city = cities.value.find(city => city.id === newDropdownCurrentCityId)
    if (city) {
      citiesStore.changeCurrent(city.name)
    }
  }
})

const emit = defineEmits([
  EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_SERVICE_CENTER
])

const openModalShowServiceCenter = () => emit(EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_SERVICE_CENTER)
</script>

<template>
  <div v-if="dropdownCurrentCityId">
    <div :class="classes.title">
      Выберите удобный филиал
    </div>
    <DropdownSelect
      v-model="dropdownCurrentCityId"
      :class="classes.dropdown"
      intent="primary"
      :options="citiesOptions"
    />
    <TabList
      :tabs="[{name: 'Список'}, {name: 'Карта'}]"
      has-full-width
    >
      <template #tab-0>
        <ServiceCenterSelection @open-modal-show-service-center="openModalShowServiceCenter" />
      </template>
      <template #tab-1>
        <Map
          has-event
          @service-center-selected="openModalShowServiceCenter"
        />
      </template>
    </TabList>
  </div>
</template>
