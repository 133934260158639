import { acceptHMRUpdate, defineStore } from "pinia"
import { ref } from "vue"

import api, { type ModelGroup } from "shared/api"

export const useModelGroupsStore = defineStore("model-groups", () => {
  const list = ref<ModelGroup[]>([])

  function getList() {
    return api.modelGroups.getList()
      .then(({ data }) => {
        list.value = data
      })
      .catch(() => {
        list.value = []
      })
  }

  return {
    list,
    getList
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useModelGroupsStore, import.meta.hot))
}
