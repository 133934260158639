<script setup lang="ts">
import { TabListItem } from "shared/lib"
import classes from "./classes"
import { ref, onMounted, PropType } from "vue"

const props = defineProps({
  hasFullWidth: {
    type: Boolean,
    default: false,
    required: false
  },
  tabs: {
    type: Array as PropType<Array<TabListItem>>,
    default () { return {} },
    required: true
  },
  tabIndex: {
    type: Number,
    required: false,
    default: 0
  }
})

const selectedTabIndex = ref(0)

onMounted(() => {
  selectedTabIndex.value = props.tabIndex
})

const emit = defineEmits(["checkTab", "click-tab"])

const selectTab = (index: number) => {
  emit("checkTab", index)
  selectedTabIndex.value = index
  emit("click-tab", index)
}
</script>

<template>
  <div :class="hasFullWidth ? classes.wrapperFullWidth : ''">
    <div
      :class="classes.list"
      role="tablist"
    >
      <div
        v-for="(item, index) in tabs"
        :id="`tab-${index}`"
        :key="index"
        role="tab"
        :aria-selected="index === selectedTabIndex"
        :tabindex="index === selectedTabIndex ? 0 : -1"
        :class="[classes.tab, index === selectedTabIndex ? classes.tabActive : '']"
        @click="selectTab(index)"
        @keydown.enter.prevent="selectTab(index)"
      >
        {{ item.name }}
      </div>
    </div>
    <div
      role="tabpanel"
      :aria-labelledby="`tab-${selectedTabIndex}`"
    >
      <slot :name="`tab-${selectedTabIndex}`" />
    </div>
  </div>
</template>
