const wrapper = "relative"

const wrapperInvalid = [
  "group",
  "is-invalid"
]

const primary = [
  "border",
  "border-solid",
  "border-border-primary",
  "focus:border-border-active",
  "bg-bg-main-primary",
  "rounded-lg",
  "font-normal",
  "text-[16px]/[130%]",
  "text-primary",
  "duration-300",
  "outline-0",
  "appearance-none"
]

const input = [
  "w-full",
  "min-h-[56px]",
  "py-[18px]",
  "px-[15px]",
  "placeholder:text-ghost",
  "placeholder:opacity-0",
  "placeholder:invisible",
  "peer",
  "focus:pt-[23px]",
  "focus:py-[15px]",
  "focus:pb-[13px]",
  "[&:not(:placeholder-shown)]:pt-[23px]",
  "[&:not(:placeholder-shown)]:py-[15px]",
  "[&:not(:placeholder-shown)]:pb-[13px]"
]

const searchInput = [
  "py-2",
  "px-[13px]",
  "border",
  "border-border-active"
]

const invalidInput = [
  "!border-border-error",
  "bg-bg-red-300"
]

const invalidText = [
  "text-[11px]/[120%]",
  "text-error",
  "mt-2"
]

const label = [
  "absolute",
  "text-ghost",
  "left-[18px]",
  "top-[50%]",
  "-translate-y-2/4",
  "transition-all",
  "duration-300",
  "cursor-text",
  "pointer-events-none",
  "peer-focus:top-[20px]",
  "peer-focus:z-10",
  "peer-focus:text-[11px]",
  "peer-focus:text-ghost",
  "peer-focus:transition-all",
  "peer-focus:duration-200",
  "peer-[:not(:placeholder-shown)]:top-[20px]",
  "peer-[:not(:placeholder-shown)]:z-10",
  "peer-[:not(:placeholder-shown)]:text-[11px]",
  "peer-[:not(:placeholder-shown)]:text-ghost",
  "peer-[:not(:placeholder-shown)]:transition-all",
  "peer-[:not(:placeholder-shown)]:duration-200",
  "group-[.is-invalid]:top-[38%]"
]

const icon = [
  "absolute",
  "right-[16px]",
  "top-[30px]",
  "-translate-y-1/2"
]

export default {
  wrapper,
  wrapperInvalid,
  primary,
  input,
  searchInput,
  invalidInput,
  invalidText,
  label,
  icon
}
