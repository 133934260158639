import { cx } from "cva"

const root = cx([
  "bg-[rgb(100_100_100_/_20%)]",
  "rounded-3xl",
  "w-[28px]",
  "h-[28px]",
  "mb-1",
  "flex",
  "items-center",
  "justify-center",
  "cursor-pointer",
  "transition",
  "delay-500",
  "hover:opacity-80"
])

const active = cx([
  "bg-bg-red-error"
])

export default {
  root,
  active
}
