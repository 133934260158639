import { skeletonStyles } from "shared/lib"

const wrapper = [
  "my-6",
  "mx-0",
  "xl:m-0"
]

const title = [
  "font-bold",
  "text-primary",
  "text-[21px]",
  "mb-2",
  "xl:text-[16px]/[19px]",
  "xl:mb-4"
]

const skeletonRectangle = [
  ...skeletonStyles,
  "w-full",
  "p-4",
  "rounded-xl"
]

const periods = [
  "flex",
  "items-center",
  "h-[60px]"
]

const periodsList = [
  "flex",
  "items-center",
  "overflow-hidden",
  "w-full",
  "xl:max-w-[350px]"
]

const periodsItem = [
  "relative",
  "py-2",
  "px-2.5",
  "h-[37px]",
  "flex-1",
  "grid",
  "justify-center",
  "items-center",
  "text-[16px]/[0]",
  "text-center",
  "bg-bg-main-primary",
  "cursor-pointer",
  "border-t",
  "border-t-solid",
  "border-t-border-primary",
  "border-b",
  "border-b-solid",
  "border-b-border-primary",
  "last:border-r",
  "last:border-r-solid",
  "last:border-r-primary",
  "last:rounded-[0_5px_5px_0]",
  "first:border-l",
  "first:border-l-solid",
  "first:border-l-primary",
  "first:rounded-[5px_0_0_5px]",
  "xl:whitespace-nowrap"
]

const periodsItemActive = [
  "!bg-bg-main-thirdly",
  "!border-t-0",
  "!border-b-0",
  "!text-primary",
  "!rounded-[5px]",
  "!h-[60px]",
  "!text-[30px]",
  "!font-bold",
  "!grid-rows-[repeat(2,_auto)]",
  "!pt-3",
  "[&_span:last-child]:!text-[16px]"
]

const periodsItemHit = [
  "after:content-['ХИТ']",
  "after:absolute",
  "after:top-[1px]",
  "after:right-[4px]",
  "after:text-[6px]/[9px]",
  "after:font-bold",
  "after:bg-bg-red-error",
  "after:rounded-[5px]",
  "after:px-[5px]",
  "after:text-primary-on-color"
]

export default {
  wrapper,
  title,
  skeletonRectangle,
  periods,
  periodsList,
  periodsItem,
  periodsItemActive,
  periodsItemHit
}
