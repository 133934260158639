import { acceptHMRUpdate, defineStore } from "pinia"
import api, { CartOrder, Device, OrderCreationData, Price, ServiceCenter } from "shared/api"
import { CartPromoCode, CartUserData, PaymentMethod, emitMixpanelEventOrderCreated } from "shared/lib"
import { ref, computed } from "vue"
import { useServiceCentersStore } from "./service-centers"
import { useUtmStore } from "./utm"
import { ROUTE_NAMES } from "shared/constants"
import { router } from "app/providers"

export const useCartStore = defineStore("cart", {
  state: () => {
    const device = ref<Device | null>()
    const paymentMethod = ref<PaymentMethod>()
    const promoCode = ref<CartPromoCode>({
      id: "",
      value: "",
      type: "",
      loading: false,
      valid: false
    })
    const userData = ref<CartUserData>({
      Email: "",
      CustomerName: "",
      CustomerPhone: ""
    })
    const isLoading = ref<boolean>(false)

    const order = ref<CartOrder | null>()
    const qrCode = ref<string | null>()
    const price = ref<Price | null>()
    const serviceCenter = ref<ServiceCenter | null>()

    const addDeviceToCart = (newDevice: Device) => device.value = newDevice
    const removeDeviceFromCart = () => device.value = null
    const clearCart = () => device.value = null
    const isEmptyCart = computed(() => !device.value)
    const isDeviceAlreadyInCart = (targetDevice: Device) => device.value?.Id === targetDevice?.Id
    const isPromocodeApplied = computed(() => promoCode.value.valid)

    const saveUserData = (newUserData: CartUserData) => userData.value = newUserData

    const totalSum = computed(() => {
      if (!device.value) return 0

      return device.value.Price
    })

    const promoCodeDiscount = computed(() => {
      if (!promoCode.value?.valid || !totalSum.value) return 0

      if (promoCode.value.type === "Percent") {
        return totalSum.value * (Number(promoCode.value.value) / 100)
      } else {
        return Number(promoCode.value.value)
      }
    })

    const paymentMethodDiscount = computed(() => {
      if (!paymentMethod.value || !paymentMethod.value.discountPercentage || !totalSum.value) return 0

      return Math.floor((totalSum.value / 100) * paymentMethod.value.discountPercentage)
    })

    const discountSum = computed(() => {
      // Добавляем скидку по промокодам и за способ оплаты
      return  Number(promoCodeDiscount.value) + Number(paymentMethodDiscount.value)
    })

    const totalSumAfterDiscount = computed(() => {
      if (!totalSum.value) return 0

      // Вычитаем скидки
      let sum = totalSum.value - discountSum.value

      // Если доплачиваем мы – сумма 0
      if (sum <= 0) {
        sum = 0
      }

      // Округляем
      return sum
    })

    const applyPromoCode = (async (inputPromoCode: string) => {
      if (!inputPromoCode) return

      promoCode.value.loading = true
      try {
        const { data } = await api.promocodes.get(inputPromoCode)
        promoCode.value = {
          id: data.id,
          type: data.type,
          value: data.value,
          loading: false,
          valid: true
        }
      } catch (error) {
        promoCode.value.loading = false
        promoCode.value.valid = false
      }
    })

    const createOrder = async () => {
      const utmStore = useUtmStore()
      const serviceCentersStore = useServiceCentersStore()

      if (!paymentMethod.value || !device.value || !totalSum.value || !userData.value || !serviceCentersStore.current) return

      try {
        isLoading.value = true

        const orderData: OrderCreationData = {
          ...userData.value,
          BuyoutCaId: device.value.Id,
          ModelPriceId: device.value.ModelPriceId,
          ServiceCenterId: serviceCentersStore.current.id,
          Comment: "",
          Quantity: 1,
          Total: totalSum.value,
          FromSite: "PedantMarket",
          PaymentType: paymentMethod.value.key,
          UtmTags: utmStore.utmTagsAsString,
          ArrivalAt: null,
          FinalAmount: totalSumAfterDiscount.value,
          DiscountAmount: promoCodeDiscount.value + paymentMethodDiscount.value
        }

        if (promoCode.value.id) {
          orderData.AppliedPromocodeId = promoCode.value.id
        }

        const { data } = await api.orders.create(orderData)
        order.value = data
        emitMixpanelEventOrderCreated(device.value, serviceCentersStore.current, isPromocodeApplied.value, paymentMethod.value)

        return data
      } catch (error) {
        console.error(error)
        throw error
      }
    }

    const getOrder = (orderId: string) => {
      api.orders.getItem(orderId)
        .then(({ data }) => {
          order.value = data.data as CartOrder
          qrCode.value = data.additional.QrCode
          serviceCenter.value = data.relations.service_center
          price.value = data.relations.price
        })
        .catch(async () => {
          order.value = null
          qrCode.value = null
          serviceCenter.value = null
          price.value = null
          await router.push({ name: ROUTE_NAMES.NOT_FOUND })
        })
    }

    const redirectToOnlinePayment = async() => {
      if (!order.value || !device.value || !userData.value) return

      const paymentParams = {
        Amount: totalSumAfterDiscount.value * 100, // Сумма в копейках,
        OrderId: order.value.Id,                   // id заказа
        Description: device.value.FullName,        // что оплачивает покупатель
        DATA: {
          Phone: userData.value.CustomerPhone      // номер телефона покупателя
        }
      }

      const { data } = await api.tinkoff.createOrder(paymentParams)

      window.location.assign(data.PaymentURL)
    }

    const redirectToThanksPage = () => {
      if (!order.value?.Id) {
        return
      }

      router.push({
        name: ROUTE_NAMES.THANKS,
        params: {
          id: order.value.Id
        }
      })
    }

    return {
      device,
      addDeviceToCart,
      removeDeviceFromCart,
      isDeviceAlreadyInCart,
      isEmptyCart,
      isPromocodeApplied,
      clearCart,
      paymentMethod,
      totalSum,
      paymentMethodDiscount,
      discountSum,
      promoCodeDiscount,
      totalSumAfterDiscount,
      promoCode,
      applyPromoCode,
      userData,
      saveUserData,
      order,
      price,
      serviceCenter,
      qrCode,
      createOrder,
      getOrder,
      redirectToOnlinePayment,
      redirectToThanksPage
    }
  },
  persist: true
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(
    useCartStore,
    import.meta.hot
  ))
}
