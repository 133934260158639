<script setup lang="ts">
import classes from "./classes"
import { PropType } from "vue"
import { Device } from "shared/api"
import { getNextSundayDate, formatCurrency, emitMixpanelEventCartAdd, emitYandexMetrika, YANDEX_METRIKA_GOALS } from "shared/lib"
import { Icon } from "shared/ui"
import { ROUTE_NAMES } from "shared/constants"
import { useRouter } from "vue-router"
import { useCartStore } from "stores"

const router = useRouter()
const cartStore = useCartStore()

defineProps({
  device: {
    type: Object as PropType<Device>,
    default () { return {} },
    required: true
  }
})

const addToCartAndRedirect = (device: Device) => {
  cartStore.addDeviceToCart(device)
  emitMixpanelEventCartAdd(device)
  emitYandexMetrika(YANDEX_METRIKA_GOALS.V_KORZINU)
  router.push({ name: ROUTE_NAMES.CART })
}
</script>

<template>
  <div
    :class="classes.wrapper"
    @click="addToCartAndRedirect(device)"
  >
    <div :class="classes.discount">
      <div :class="classes.discountPercent">
        -12%
      </div>
      до {{ getNextSundayDate() }}
    </div>
    <div :class="classes.action">
      <div :class="classes.actionInfo">
        <div :class="classes.discountTitle">
          Купить за полную стоимость
        </div>
        <div :class="classes.price">
          <div :class="classes.priceNewPrice">
            {{ formatCurrency(device.Price) }} ₽
          </div>
          <div :class="classes.priceOldPrice">
            {{ formatCurrency(device.FullPrice) }} ₽
          </div>
        </div>
      </div>
      <Icon
        name="common/chevron"
        width="10"
        height="30"
        class-name="text-icon-ghost -rotate-90"
      />
    </div>
  </div>
</template>
