import { acceptHMRUpdate, defineStore } from "pinia"
import { ref } from "vue"

import api, { ModelColor } from "shared/api"

export const useModelColorsStore = defineStore("model-colors", () => {
  const list = ref<ModelColor[]>([])

  function getList() {
    return api.modelColors.getList()
      .then(({ data }) => {
        list.value = data
      })
      .catch(() => {
        list.value = []
      })
  }

  return {
    list,
    getList
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useModelColorsStore, import.meta.hot))
}
