<script setup lang='ts'>
import { markRaw, ref, shallowRef, watch } from "vue"
import { useRoute } from "vue-router"
import {Routing} from "pages"
import { BasicLayout, Spinner } from "shared/ui"
import "./styles/index.css"

const layout = shallowRef()
const route = useRoute()

const layoutLoading = ref(true)

watch(
  () => [route.meta?.layout, BasicLayout],
  () => {
    layoutLoading.value = true

    try {
      layout.value = markRaw(route.meta.layout ?? BasicLayout)
    } catch (error) {
      layout.value = markRaw(BasicLayout)
    }

    window.prerenderReady = true
    layoutLoading.value = false
  }
)
</script>

<template>
  <component
    :is="layout"
    v-if="!layoutLoading"
  >
    <Routing />
  </component>
  <Spinner v-else />
</template>
