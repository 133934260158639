import { acceptHMRUpdate, defineStore } from "pinia"
import { computed, ref } from "vue"

import api, { type City } from "shared/api"
import { DEFAULT_CURRENT_CITY } from "shared/constants"

export const useCitiesStore = defineStore("cities", () => {
  const cityName = ref<string | null>(localStorage.getItem("current_city"))
  const hasConfirm = ref<boolean>(localStorage.getItem("current_city_confirmed") === "true")
  const list = ref<City[]>([])

  const current = computed(() => {
    return list.value.find(city => city.name === cityName.value) ??
      list.value.find(city => city.name === "Москва")
  })

  async function getCurrent() {
    return api.dadata.getAddressByIp()
      .then(({ data }) => {
        const result = data.location?.data.city ?? DEFAULT_CURRENT_CITY

        cityName.value = result
        localStorage.setItem("current_city", result)
      })
      .catch(() => {
        cityName.value = null
        localStorage.removeItem("current_city")
      })
  }

  async function getList() {
    return api.cities.getList()
      .then(({ data }) => { list.value = data })
      .catch(() => { list.value = [] })
  }

  function confirm() {
    hasConfirm.value = true
    localStorage.setItem("current_city_confirmed", `${hasConfirm.value}`)
  }

  function changeCurrent(newCityName: string) {
    cityName.value = newCityName
    localStorage.setItem("current_city", newCityName)
  }

  return {
    current,
    hasConfirm,
    list,
    getCurrent,
    getList,
    confirm,
    changeCurrent
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCitiesStore, import.meta.hot))
}
