import { cx } from "cva"

const list = cx([
  "grid",
  "mt-5"
])

const horizontalList = [
  "gap-[17px]",
  "xl:gap-9",
  "grid-rows-3",
  "xl:grid-rows-none",
  "xl:grid-cols-3"
]

const verticalList = [
  "gap-2",
  "grid-rows-3"
]

const li = cx([
  "grid",
  "grid-cols-[20px_1fr]",
  "gap-6",
  "items-center",
  "leading-4",
  "xl:gap-[21px]"
])

const text = cx([
  "leading-5",
  "xl:leading-[21px]"
])

const number = cx([
  "text-[36px]/[43px]",
  "font-bold",
  "text-icon-accent",
  "text-right",
  "xl:text-[41px]"
])

export default {
  list,
  horizontalList,
  verticalList,
  li,
  text,
  number
}
