const modal = [
  "absolute",
  "inset-0"
]

const bottomModal = [
  "absolute",
  "inset-x-0",
  "bottom-0",
  "w-full",
  "bg-bg-main-secondary",
  "rounded-t-xl",
  "max-w-[640px]",
  "mx-auto"
]

const wrapper = [
  "absolute",
  "flex",
  "p-12",
  "inset-0",
  "h-full",
  "min-h-[100vh]",
  "max-h-[100vh]",
  "overflow-auto"
]

const bottomWrapper = [
  "max-h-[90vh]",
  "h-full",
  "overflow-auto",
  "rounded-t-xl"
]

const content = [
  "relative",
  "flex",
  "flex-col",
  "w-fit",
  "m-auto",
  "rounded-xl",
  "bg-bg-main-secondary",
  "overflow-hidden"
]

const bottomContent = [
  "relative",
  "flex",
  "flex-col"
]

const header = [
  "flex",
  "flex-col",
  "p-4",
  "bg-bg-main-secondary"
]

const headerWithLogo = [
  "w-full",
  "py-[30px]",
  "px-[46px]",
  "!bg-[#343434]"
]

const title = [
  "w-full",
  "p-4",
  "pt-12",
  "pb-0",
  "text-2xl/[120%]",
  "font-bold",
  "text-primary",
  "text-center"
]

const titleToLeft = [
  "!pl-[30px]",
  "!text-left"
]

const button = [
  "!absolute",
  "top-[15px]",
  "right-[15px]",
  "z-10"
]

const main = [
  "p-4",
  "xl:px-[46px]"
]

const blockModal = [
  "flex",
  "absolute z-[999]",
  "w-screen",
  "h-screen",
  "bg-bg-main-primary",
  "bg-opacity-45",
  "cursor-not-allowed"
]

export default {
  modal,
  bottomModal,
  wrapper,
  bottomWrapper,
  content,
  bottomContent,
  header,
  headerWithLogo,
  title,
  titleToLeft,
  button,
  main,
  blockModal
}
