import Routing from "./index.vue"
import { type RouteRecordRaw } from "vue-router"

import { ROUTE_NAMES } from "shared/constants"

// pages sort by alphabetically
const AccountPage = () => import("./account")
const CartPage = () => import("./cart")
const CatalogPage = () => import("./catalog")
const CatalogCategoriesPage = () => import("./catalog-categories")
const CatalogDevicePage = () => import("./catalog-device")
const CompanyPage = () => import("./company")
const CompanyDetailsPage = () => import("./company-details")
const ComparisonPage = () => import("./comparison")
const ConsentDocumentPage = () => import("./consent-document")
const ContactsPage = () => import("./contacts")
const FavouritePage = () => import("./favourite")
const GarantiaPage = () => import("./garantia")
const GarantiaServicePage = () => import("./garantia-service")
const GuaranteePage = () => import("./guarantee")
const HomePage = () => import("./home")
const NotFoundPage = () => import("./not-found")
const PaymentPage = () => import("./payment")
const RedirectPage = () => import("./redirect")
const ReturnPage = () => import("./return")
const ReviewsPage = () => import("./reviews")
const SearchPage = () => import("./search")
const SubscriptionPage = () => import("./subscription")
const SubscriptionFormPage = () => import("./subscription-form")
const SubscriptionThanksPage = () => import("./subscription-thanks")
const SubscriptionDescriptionPage = () => import("./subscription-description")
const SubscriptionOutOfStockPage = () => import("./subscription-out-of-stock")
const ThanksPage = () => import("./thanks")
const TradeInPage = () => import("./trade-in")
const PartnerCredentialsPage = () => import("./partner-credentials")

// routes sort by alphabetically
export const routes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAMES.ACCOUNT,
    component: AccountPage,
    path: "/account"
  },
  {
    name: ROUTE_NAMES.CART,
    component: CartPage,
    path: "/cart"
  },
  {
    name: ROUTE_NAMES.CATALOG_CATEGORIES,
    component: CatalogCategoriesPage,
    path: "/catalog"
  },
  {
    name: ROUTE_NAMES.CATALOG_MODEL_GROUP,
    component: CatalogPage,
    path: "/:modelGroupSlug"
  },
  {
    name: ROUTE_NAMES.CATALOG_MODEL,
    component: CatalogPage,
    path: "/:modelGroupSlug/:modelSlug"
  },
  {
    name: ROUTE_NAMES.CATALOG_DEVICE,
    component: CatalogDevicePage,
    path: "/:modelGroupSlug/:modelSlug/:id"
  },
  {
    name: ROUTE_NAMES.COMPANY,
    component: CompanyPage,
    path: "/company"
  },
  {
    name: ROUTE_NAMES.COMPANY_DETAILS,
    component: CompanyDetailsPage,
    path: "/company-details"
  },
  {
    name: ROUTE_NAMES.COMPARISON,
    component: ComparisonPage,
    path: "/comparison"
  },
  {
    name: ROUTE_NAMES.CONSENT_DOCUMENT,
    component: ConsentDocumentPage,
    path: "/documents/consent"
  },
  {
    name: ROUTE_NAMES.CONTACTS,
    component: ContactsPage,
    path: "/contacts"
  },
  {
    name: ROUTE_NAMES.FAVOURITE,
    component: FavouritePage,
    path: "/favourite"
  },
  {
    name: ROUTE_NAMES.GARANTIA,
    component: GarantiaPage,
    path: "/garantia"
  },
  {
    name: ROUTE_NAMES.GARANTIA_SERVICE,
    component: GarantiaServicePage,
    path: "/garantijnoe-obsluzhivanie"
  },
  {
    name: ROUTE_NAMES.GUARANTEE,
    component: GuaranteePage,
    path: "/guarantee"
  },
  {
    name: ROUTE_NAMES.HOME,
    component: HomePage,
    path: "/"
  },
  {
    name: ROUTE_NAMES.NOT_FOUND,
    component: NotFoundPage,
    path: "/:pathMatch(.*)*"
  },
  {
    name: ROUTE_NAMES.PAYMENT,
    component: PaymentPage,
    path: "/payment"
  },
  {
    name: ROUTE_NAMES.RETURN,
    component: ReturnPage,
    path: "/return"
  },
  {
    name: ROUTE_NAMES.REVIEWS,
    component: ReviewsPage,
    path: "/reviews"
  },
  {
    name: ROUTE_NAMES.SEARCH,
    component: SearchPage,
    path: "/search/:searchQuery"
  },
  {
    name: ROUTE_NAMES.SUBSCRIPTION,
    component: SubscriptionPage,
    path: "/subscription"
  },
  {
    name: ROUTE_NAMES.SUBSCRIPTION_FORM,
    component: SubscriptionFormPage,
    path: "/subscription/form"
  },
  {
    name: ROUTE_NAMES.SUBSCRIPTION_FORM_FILLED,
    path: "/subscription/:subscriptionOrderId",
    component: SubscriptionFormPage
  },
  {
    name: ROUTE_NAMES.SUBSCRIPTION_DESCRIPTION,
    component: SubscriptionDescriptionPage,
    path: "/podpiska"
  },
  {
    name: ROUTE_NAMES.SUBSCRIPTION_OUT_OF_STOCK,
    component: SubscriptionOutOfStockPage,
    path: "/out-of-stock"
  },
  {
    name: ROUTE_NAMES.SUBSCRIPTION_THANKS,
    path: "/subscription-order-accepted/:id",
    component: SubscriptionThanksPage
  },
  {
    name: ROUTE_NAMES.THANKS,
    component: ThanksPage,
    path: "/thanks/:id"
  },
  {
    name: ROUTE_NAMES.TRADE_IN,
    component: TradeInPage,
    path: "/trade-in"
  },
  {
    name: ROUTE_NAMES.PARTNER_CREDENTIALS,
    component: PartnerCredentialsPage,
    path: "/partner-credentials"
  },
  {
    name: ROUTE_NAMES.REDIRECT,
    component: RedirectPage,
    path: "/r"
  },
  {
    name: ROUTE_NAMES.REDIRECT,
    component: RedirectPage,
    path: "/r/:slug"
  },
  {
    name: ROUTE_NAMES.SIGNING,
    component: RedirectPage,
    path: "/s/:slug"
  },
  {
    name: ROUTE_NAMES.PAYMENT_REDIRECT,
    component: RedirectPage,
    path: "/g/:slug"
  },
  {
    name: ROUTE_NAMES.ADVANCED_PAYMENT_REDIRECT,
    component: RedirectPage,
    path: "/ga/:slug"
  },
  {
    name: ROUTE_NAMES.INSTALLMENT_SIGNING,
    component: RedirectPage,
    path: "/i/:slug"
  }
]

export const redirects: RouteRecordRaw[] = [
  {
    path: "/r",
    redirect: "/"
  }
]

export { Routing }
