import { createApp } from "vue"
import {router, store} from "./providers"
import { initYandexMetrika, initSentry } from "shared/lib"
import App from "./index.vue"

import { createVfm } from "vue-final-modal"
import "vue-final-modal/style.css"

import VueLazyload from "vue-lazyload"

/*--------- VUE YANDEX MAPS START ---------*/
import { createYmaps } from "vue-yandex-maps"
import type { VueYandexMaps } from "vue-yandex-maps"

const settings: VueYandexMaps.PluginSettings = {
  apikey: import.meta.env.APP_YANDEX_MAP_API_KEY,
  lang: "ru_RU"
}
/*--------- VUE YANDEX MAPS END ---------*/

/*--------- VUE UNHEAD START ---------*/
import { createHead } from "@unhead/vue"

const head = createHead()
/*--------- VUE UNHEAD END ---------*/

/*--------- AXIOS INTERCEPTORS START ---------*/
import * as Sentry from "@sentry/vue"
import axios from "axios"

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 500) {
      Sentry.captureException(error, {
        extra: {
          url: error.config.url,
          method: error.config.method,
          data: error.config.data,
          headers: error.config.headers,
          response: error.response.data
        }
      })
    }
    return Promise.reject(error)
  }
)
/*--------- AXIOS INTERCEPTORS END ---------*/

const app = createApp(App)
  .use(router)
  .use(store)
  .use(head)
  .use(createYmaps(settings))
  .use(createVfm())
  .use(VueLazyload)

initYandexMetrika(app, router)
initSentry(app)

app.config.errorHandler = function (error) {
  Sentry.captureException(error)
}

export default app
