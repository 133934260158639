import { pedantMarketApi } from "../clients"
import type { SeoContent } from "../types"

const get = (url: string = "/", model_id: string = "") => {
  const params = model_id ? { model_id } : { url }

  return pedantMarketApi.get<SeoContent[]>("/seo-contents", { params })
}

export const seo = {
  get
}
