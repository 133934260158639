const wrapper = [
  "flex",
  "flex-col"
]

const phone = [
  "mb-3",
  "xl:mb-[3px]",
  "text-base/[130%]",
  "xl:text-2xl/[130%]",
  "font-bold",
  "whitespace-nowrap"
]

const time = [
  "inline-block",
  "mb-[18px]",
  "xl:mb-[16px]",
  "text-secondary-on-color",
  "text-sm/[120%]",
  "xl:text-base/[130%]"
]

export default {
  wrapper,
  phone,
  time
}
