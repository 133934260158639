<script setup lang="ts">
import classes from "./classes"
import { PropType, computed } from "vue"
import { Device } from "shared/api"
import { Button, Icon } from "shared/ui"
import { useUserStore } from "stores"

const props = defineProps({
  device: {
    type: Object as PropType<Device>,
    default () { return {} },
    required: true
  }
})

const userStore = useUserStore()

const isComparableDevice = computed(() => userStore.isComparableDevice(props.device))
</script>

<template>
  <Button
    :title="!isComparableDevice ? 'Добавить товар в сравнение' : 'Убрать товар из сравнения'"
    @click="userStore.toggleComparableDevice(props.device)"
  >
    <Icon
      name="common/comparison-white"
      width="12"
      height="18"
      :class="[classes.root, isComparableDevice ? classes.active : '']"
    />
  </Button>
</template>
