const gallery = [
  "flex",
  "overflow-x-scroll"
]

const galleryItem = [
  "w-[53px]",
  "h-[53px]",
  "p-[3px]",
  "cursor-pointer",
  "rounded-xl",
  "border-2",
  "border-solid",
  "border-white",
  "mr-[26px]",
  "last:mr-0"
]

const activeGalleryItem = "!border-yellow-yellow"

export default {
  gallery,
  galleryItem,
  activeGalleryItem
}
