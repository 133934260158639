import { pedantMarketApi } from "../clients"
import type { Promocode } from "../types"

const get = (promocode: string) => {
  return pedantMarketApi.get<Promocode>(`/promocodes/${promocode}`)
}

export const promocodes = {
  get
}
