<script setup lang="ts">
import classes from "./classes"
import { computed, PropType } from "vue"
import { Button } from "shared/ui"
import { Device } from "shared/api"
import {
  YANDEX_METRIKA_GOALS,
  emitYandexMetrika,
  numberSpace,
  emitMixpanelEventCardSubscriptionMoreLinkClicked,
  getMonthlyPaymentOldPrice
} from "shared/lib"
import { useRouter } from "vue-router"
import { ROUTE_NAMES } from "shared/constants"
import { CatalogDeviceSubscriptionCardSteps } from "widgets"
import { storeToRefs } from "pinia"
import { useSubscriptionStore } from "stores"

const props = defineProps({
  device: {
    type: Object as PropType<Device>,
    default () { return {} },
    required: true
  },
  monthlyPayment: {
    type: Number,
    required: false,
    default: 0
  }
})

const router = useRouter()

const redirectToSubscribe = (device: Device) => {
  if (device) {
    emitMixpanelEventCardSubscriptionMoreLinkClicked(device)
    emitYandexMetrika(YANDEX_METRIKA_GOALS.CARD_SUBSCRIPTION_MORE_LINK_CLICKED)
  }

  router.push({ name: ROUTE_NAMES.SUBSCRIPTION })
}

const subscriptionStore = useSubscriptionStore()
const { selectedPercent } = storeToRefs(subscriptionStore)
const monthlyPaymentOld = computed(() => getMonthlyPaymentOldPrice(props.device))
</script>

<template>
  <div :class="classes.wrapper">
    <div :class="classes.header">
      <div :class="classes.title">
        По подписке
      </div>
    </div>
    <CatalogDeviceSubscriptionCardSteps />
    <div
      v-if="monthlyPayment"
      :class="classes.total"
    >
      <div :class="classes.titleBig">
        {{ numberSpace(monthlyPayment) }} ₽/мес
      </div>
      <div
        v-if="selectedPercent?.Months === 12"
        class="flex items-center"
      >
        <div class="text-ghost text-2xl font-semibold line-through mr-1.5">
          {{ numberSpace(monthlyPaymentOld!) }} ₽
        </div>
        <div class="px-2 rounded-lg font-bold text-xs text-primary-on-color bg-bg-red-error uppercase py-[3px] text-[10px] w-fit">
          -25%
        </div>
      </div>
    </div>
    <Button
      intent="base-primary"
      size="middle"
      :class="classes.button"
      @click="redirectToSubscribe(device)"
    >
      Подробнее о подписке
    </Button>
  </div>
</template>
