<script setup lang="ts">
import classes from "./classes"
import { computed } from "vue"
import { cva, type VariantProps } from "cva"
import { Icon } from ".."
import { maskito as vMaskito } from "@maskito/vue"
import options from "./mask"

const input = cva([], {
  variants: {
    intent: {
      primary: classes.primary
    },
    type: {
      input: classes.input,
      search: classes.searchInput
    }
  }
})

type InputTextProps = VariantProps<typeof input>

interface Props {
  modelValue?: string,
  intent: InputTextProps["intent"],
  type: InputTextProps["type"],
  id?: string,
  placeholder?: string,
  error?: string,
  className?: string,
  required?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    modelValue: "",
    intent: "primary",
    type: "input",
    id: "",
    placeholder: "",
    error: "",
    className: "",
    required: false
  }
)

const emit = defineEmits(["update:modelValue"])

const inputValue = computed({
  get() {
    return props.modelValue
  },

  set(value) {
    emit("update:modelValue", value)
  }
})
</script>

<template>
  <div :class="[classes.wrapper, error ? classes.wrapperInvalid : '']">
    <input
      :id="id"
      v-model="inputValue"
      v-maskito="options"
      type="tel"
      :class="[input({ intent, type }), error ? classes.invalidInput : '', className]"
      :placeholder="placeholder"
      :required="required"
    >
    <label
      v-if="id"
      :class="classes.label"
      :for="id"
    >
      {{ placeholder }}
    </label>
    <div
      v-show="error"
      :class="classes.invalidText"
    >
      {{ error }}
    </div>
    <Icon
      v-if="error"
      :class="classes.icon"
      name="common/input-error"
      width="20"
      height="20"
    />
  </div>
</template>
