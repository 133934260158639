<script setup lang="ts">
import { computed } from "vue"
import { cx } from "cva"
import { SoldDevicesNumber } from "entities/sold-devices-number"
import { Logo, Button } from "shared/ui"
import { Contacts } from "shared/ui"
import { FooterMainMenu, MobileMenuLinks } from "widgets"

const footer = cx([
  "bg-bg-blue-accent",
  "px-[20px]",
  "py-16",
  "text-primary-on-color"
])

const hr = cx([
  "border-y",
  "border-border-primary-on-color",
  "mt-[32px]",
  "xl:mt-[38px]",
  "mb-[32px]",
  "xl:mb-[62px]"
])

const text = cx([
  "text-[10px]/[120%]",
  "xl:text-[11px]/[130%]",
  "text-secondary-on-color",
  "mb-1.5",
  "last:mb-0"
])

const lgHidden = cx([
  "block",
  "xl:hidden"
])

const xsHidden = cx([
  "hidden",
  "xl:block"
])

const currentYear = computed(() => {
  return new Date().getFullYear()
})
</script>

<template>
  <footer :class="footer">
    <div class="container mx-auto">
      <div class="flex flex-col xl:flex-row justify-between">
        <div class="xl:mr-8">
          <Logo
            class="mb-[39px] xl:mb-6 w-[192px] h-[48px]"
            text-class-name="!text-base/[130%] tracking-[.35px]"
          />
          <Contacts class="xl:mb-[54px]" />
          <hr :class="[hr, 'block', 'xl:hidden']">
          <SoldDevicesNumber :class="xsHidden" />
        </div>
        <FooterMainMenu :class="[xsHidden, 'xl:flex']" />
        <MobileMenuLinks :class="['mb-[95px]', lgHidden]" />
        <SoldDevicesNumber :class="['mb-[38px]', lgHidden]" />
      </div>
      <hr :class="[hr, xsHidden]">
      <div class="flex flex-col xl:flex-row justify-between">
        <div class="max-w-max xl:max-w-[835px] mb-[46px] xl:mb-0">
          <p :class="text">
            <span>Посещая сайт pedant.market, вы соглашаетесь с положениями, изложенными в </span>
            <Button
              type="router-link"
              intent="link-on-color-secondary"
              underline="on"
              class="!w-auto !inline-block"
              url="/politics"
              target="_blank"
            >
              политике по обработке персональных данных
            </Button>
            <span>, а также согласие с текстом </span>
            <Button
              type="router-link"
              intent="link-on-color-secondary"
              underline="on"
              class="!w-auto !inline-block"
              url="/ofertaPM"
              target="_blank"
            >
              публичной оферты
            </Button>.
          </p>
          <p :class="text">
            <span>Товарные знаки используется с целью описания товара, несут информативный характер. Маркетплейс не связан с компаниями Правообладателями товарных знаков и/или с ее официальными представителями в отношении товаров, которые уже были введены в гражданский оборот в смысле статьи 1487 ГК РФ.</span>
          </p>
          <p :class="text">
            <span>Представленные на сайте товарные знаки используются с правомерной информационной и описательной целью. iPhone, Macbook, iPad - правообладатель Apple Inc. (Эпл Инк.); Huawei и Honor - правообладатель HUAWEI TECHNOLOGIES CO., LTD. (ХУАВЕЙ ТЕКНОЛОДЖИС КО., ЛТД.); Samsung – правообладатель Samsung Electronics Co. Ltd. (Самсунг Электроникс Ко., Лтд.); Xiaomi - правообладатель Xiaomi Inc.</span>
          </p>
        </div>
        <div :class="[text, 'flex']">
          <p><span>© {{ currentYear }} Pedant.Market</span></p>
        </div>
      </div>
    </div>
  </footer>
</template>
