import { acceptHMRUpdate, defineStore } from "pinia"
import { ref } from "vue"

import api, { type ServiceCenter } from "shared/api"
import { useCitiesStore } from "./cities"

export const useServiceCentersStore = defineStore("service-centers", () => {
  const current = ref<ServiceCenter>()
  const shownInModal = ref<ServiceCenter>()
  const list = ref<ServiceCenter[]>([])
  const cities = useCitiesStore()

  function getList() {
    if (!cities.current?.id) return

    api.serviceCenters.getList(cities.current.id)
      .then(({ data }) => { list.value = data })
      .catch(() => { list.value = [] })
  }

  return { current, shownInModal, list, getList }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(
    useServiceCentersStore,
    import.meta.hot
  ))
}
