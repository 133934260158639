import { cx } from "cva"

const wrapper = cx([
  "flex",
  "justify-between",
  "flex-col",
  "xl:flex-row",
  "aspect-square",
  "xs:aspect-[5/4]",
  "xl:aspect-auto",
  "items-center",
  "p-8",
  "xl:ps-14",
  "xl:pe-16",
  "xl:py-12",
  "text-primary-on-color",
  "rounded-xl",
  "relative",
  "bg-cover",
  "xl:bg-contain",
  "bg-no-repeat",
  "bg-sale-pattern-mobile",
  "xl:bg-center-12",
  "xl:bg-sale-pattern"
])

const content = cx([
  "flex",
  "flex-col",
  "justify-between",
  "h-full",
  "w-full"
])

const title = cx([
  "text-[21px]",
  "leading-tight",
  "xl:text-4xl",
  "font-bold",
  "text-center",
  "xl:text-left",
  "mb-4"
])

const itemNumber = [
  "mr-2",
  "rounded-full",
  "w-5",
  "h-5",
  "flex",
  "items-center",
  "justify-center",
  "bg-snow-white-500"
]

export default {
  wrapper,
  content,
  title,
  itemNumber
}
