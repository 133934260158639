const button = [
  "ml-[5px]"
]

const wrapper = [
  "p-6",
  "w-[320px]"
]

const title = [
  "font-bold",
  "text-primary",
  "mb-2"
]

export default {
  button,
  wrapper,
  title
}
