<script setup lang="ts">
import classes from "./classes"
import { ref, watch, onMounted } from "vue"
import { storeToRefs } from "pinia"
import { YANDEX_METRIKA_GOALS, emitMixpanelEventCartBranchSelected, emitYandexMetrika, linkStyles, linkUnderlineStyles, radioInputStyles, stretchedLinkStyles } from "shared/lib"
import { useCartStore, useServiceCentersStore } from "stores"
import { Button } from "shared/ui"
import { ServiceCenter } from "shared/api"
import SweetScroll from "sweet-scroll"
import { EMIT_MODAL_FUNCTION_NAMES } from "shared/constants"

const serviceCentersStore = useServiceCentersStore()
const { list: serviceCenters, current: currentServiceCenter } = storeToRefs(serviceCentersStore)

const cartStore = useCartStore()

const scroller = ref()

onMounted(() => {
  if (serviceCenters.value.length) {
    setCurrentServiceCenter(currentServiceCenter.value || serviceCenters.value[0])

    scroller.value = SweetScroll.create(
      {
        duration: 1200,
        easing: "easeOutExpo"
      },
      "#service-center-selection"
    )

    scrollTo()
  }
})

const emit = defineEmits([
  EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_SERVICE_CENTER
])

watch(serviceCenters, () => {
  setCurrentServiceCenter(serviceCenters.value[0])
})

const setCurrentServiceCenter = (serviceCenter: ServiceCenter) => {
  serviceCentersStore.current = serviceCenter

  if (cartStore.device) {
    emitMixpanelEventCartBranchSelected(serviceCenter, cartStore.device, cartStore.isPromocodeApplied)
    emitYandexMetrika(YANDEX_METRIKA_GOALS.VIBOR_SC)
  }
}

const showInModalServiceCenter = (serviceCenter: ServiceCenter) => {
  serviceCentersStore.shownInModal = serviceCenter
  emit(EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_SERVICE_CENTER)
}

const scrollTo = () => {
  const serviceCenterSelectionElement = document.querySelector("#service-center-selection")
  const activeServiceCenterElement = document.querySelector("input[name='radio-input']:checked")

  if (serviceCenterSelectionElement && activeServiceCenterElement) {
    const parentBoundingClientRect = serviceCenterSelectionElement.getBoundingClientRect()
    const boundingClientRect = activeServiceCenterElement.getBoundingClientRect()

    scroller.value.to(boundingClientRect.top - parentBoundingClientRect.top - boundingClientRect.height / 2)
  }
}
</script>

<template>
  <div
    id="service-center-selection"
    :class="classes.wrapper"
  >
    <div
      v-for="serviceCenter in serviceCenters"
      :key="serviceCenter.id"
      :class="[classes.item, serviceCenter === currentServiceCenter ? classes.itemActive : '']"
      @click="setCurrentServiceCenter(serviceCenter)"
    >
      <input
        :class="[stretchedLinkStyles, radioInputStyles, classes.radio]"
        type="radio"
        name="radio-input"
        :value="serviceCenter.id"
        :checked="serviceCenter === currentServiceCenter"
        required
      >
      <img
        v-if="serviceCenter.images?.[serviceCenter.images.length - 1]?.url"
        :src="serviceCenter.images?.[serviceCenter.images.length - 1]?.url"
        :alt="`${serviceCenter.address}, ${serviceCenter.anchor}`"
        :class="classes.image"
      >
      <div :class="classes.content">
        <div>
          <div :class="classes.address">
            {{ serviceCenter.address }}, {{ serviceCenter.anchor }}
          </div>
          <div :class="classes.workTimes">
            {{ serviceCenter.work_times }}
          </div>
        </div>
        <Button
          :class="[linkStyles, linkUnderlineStyles, classes.button]"
          @click="showInModalServiceCenter(serviceCenter)"
        >
          Подробнее
        </Button>
      </div>
    </div>
  </div>
</template>
