<script setup lang="ts">
import classes from "./classes"
import { computed } from "vue"
import { useCatalogStore, useDevicesStore } from "stores"
import { declension } from "shared/lib"
import { useRoute } from "vue-router"
import { ROUTE_NAMES } from "shared/constants"

const route = useRoute()
const catalogStore = useCatalogStore()
const devicesStore = useDevicesStore()

const isCatalogPage = computed(() => route.name === ROUTE_NAMES.CATALOG_MODEL || route.name === ROUTE_NAMES.CATALOG_MODEL_GROUP)
const isCatalogCategoriesPage = computed(() => route.name === ROUTE_NAMES.CATALOG_CATEGORIES)

const soldDevicesNumber = computed(() => {
  if (isCatalogPage.value) return catalogStore.meta?.total
  else if (isCatalogCategoriesPage.value) return devicesStore.meta?.total

  return 0
})
</script>

<template>
  <p
    v-if="soldDevicesNumber"
    :class="classes.text"
  >
    {{ soldDevicesNumber }} {{ declension(soldDevicesNumber, ["смартфон", "смартфона", "смартфонов"]) }} в текущем каталоге
  </p>
</template>
