import { acceptHMRUpdate, defineStore } from "pinia"
import { ref, computed } from "vue"

import api, { DeviceListParams, type Device, ModelColorListParams, ModelMemoryListParams, ModelColor, ModelMemory } from "shared/api"
import { YANDEX_METRIKA_GOALS, checkIsIPhone, checkIsNew, emitYandexMetrika, getMinCreditPrice, getMonthlyPayment } from "shared/lib"

export const useCatalogDeviceStore = defineStore("catalog-device", () => {
  const isLoading = ref<boolean>(false)

  const item = ref<Device | null | undefined>()

  const isNew = computed(() => checkIsNew(item.value))
  const isIPhone = computed(() => checkIsIPhone(item.value))
  const minCreditPrice = computed(() => getMinCreditPrice(item.value))
  const monthlyPayment = computed(() => getMonthlyPayment(item.value))

  const memories = ref<ModelMemory[]>([])
  const colors = ref<ModelColor[]>([])

  const selectedMemory = ref<ModelMemory | null>(null)
  const selectedColor = ref<ModelColor | null>(null)

  const setItem = (newItem: Device) => { item.value = newItem }

  function getItem(deviceId: string) {
    isLoading.value = true

    return api.devices.getItem(deviceId)
      .then(({ data }) => {
        item.value = data

        if (data.Videos.length) {
          emitYandexMetrika(YANDEX_METRIKA_GOALS.KARTOCHKA_TOVARA_VIDEO)
        }
      })
      .catch((error: Error) => {
        item.value = null

        throw error
      })
      .finally(() => isLoading.value = false)
  }

  function getItems(params: DeviceListParams) {
    isLoading.value = true

    return api.devices.getList(params)
      .then(response => {
        return response.data.data
      })
      .finally(() => isLoading.value = false)
  }

  function getColor(params: ModelColorListParams) {

    return api.modelColors.getList(params)
      .then(response => {
        colors.value = response.data
        return response.data.find(color => color.id === item.value?.Color.Id)
      })
  }

  function getMemory(params: ModelMemoryListParams) {
    if (!item.value) return

    return api.modelMemories.getList(params)
      .then(response => {
        memories.value = response.data
        return response.data.find(memory => memory.id === item.value?.ModelMemory.Id)
      })
  }

  return {
    isLoading,
    item, isNew, isIPhone, minCreditPrice,
    monthlyPayment, memories, colors, selectedMemory, selectedColor,
    setItem, getItem, getItems, getColor, getMemory
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(
    useCatalogDeviceStore,
    import.meta.hot
  ))
}
