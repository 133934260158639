import { acceptHMRUpdate, defineStore } from "pinia"
import { ref } from "vue"

import api, {
  type Device,
  type PaginatedMeta, type PaginatedLinks, type DeviceListParams
} from "shared/api"

export const useDevicesStore = defineStore("devices", () => {
  const list = ref<Device[]>([])
  const meta = ref<PaginatedMeta>()
  const links = ref<PaginatedLinks>()

  function getList(params: DeviceListParams) {
    api.devices.getList(params)
      .then(({ data }) => {
        list.value = data.data
        meta.value = data.meta
        links.value = data.links
      })
      .catch(() => {
        list.value = []
      })
  }

  return {
    list,
    meta,
    links,
    getList
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDevicesStore, import.meta.hot))
}
