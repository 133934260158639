import { App as Application } from 'vue'
import * as Sentry from "@sentry/vue"

export const initSentry = (app: Application) => {
  Sentry.init({
    app,
    dsn: import.meta.env.APP_SENTRY_INIT_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ['error', 'warn'] // из консоли отправялем только ошибки и предупреждения
      }),
      Sentry.dedupeIntegration(), // исключаем дубли ошибок
      Sentry.httpClientIntegration({
        failedRequestStatusCodes: [400, 401, 403, 404, 405, 429, 444] // оповещение о клиентских ошибках
      }),
    ],
    sendDefaultPii: true, // добавляем к событию ошибки заголовок и данные куков
    tracesSampleRate: 1.0, // будет отправлено 100% трассировок
    tracePropagationTargets: ["localhost", 'https://pedant.market/'],
    replaysSessionSampleRate: 0.1, // будет записано 10% всех сессий пользователей
    replaysOnErrorSampleRate: 1.0, // будет записано 100% сессий, во время кот. произошла ошибка
    profilesSampleRate: 1.0, // будет отправлено 100% профилей производительности
  })
}
