<script setup lang='ts'>
import { PropType } from "vue"
import { cx } from "cva"
import { Icon } from "shared/ui"
import { useBreakpoints } from "@vueuse/core"
import { type City } from "shared/api"
import { YANDEX_METRIKA_GOALS, declension, emitYandexMetrika } from "shared/lib"

const breakpoints = useBreakpoints({ xl: 1200 })
const xlAndLarger  = breakpoints.greaterOrEqual("xl")

defineProps({
  city: {
    type: Object as PropType<City | null>,
    default () { return {} },
    required: true
  }
})

const wrapperStyles = cx([
  "flex",
  "items-center",
  "cursor-pointer",
  "duration-300",
  "hover:opacity-80"
])

const iconStyles = cx([
  "mr-2",
  "xl:mr-4",
  "p-px",
  "hidden",
  "xl:inline-block"
])

const contentStyles = cx([
  "text-[10px]/[130%]",
  "xl:text-base"
])

const nameStyles = cx([
  "font-semibold",
  "leading-[120%]",
  "xl:leading-[130%]"
])

const togglerStyles = cx([
  "hidden",
  "xl:inline",
  "text-xs",
  "font-bold"
])

const serviceCentersStyles = cx([
  "font-normal",
  "underline",
  "underline-offset-1",
  "opacity-80"
])
</script>

<template>
  <div
    v-if="city"
    :class="wrapperStyles"
    @click="emitYandexMetrika(YANDEX_METRIKA_GOALS.CITY_HEADER)"
  >
    <Icon
      name="common/location"
      :width="xlAndLarger ? 24 : 18"
      :height="xlAndLarger ? 24 : 18"
      :class-name="iconStyles"
    />
    <div :class="contentStyles">
      <div :class="nameStyles">
        {{ city?.name }} <span :class="togglerStyles">▼</span>
      </div>
      <div :class="serviceCentersStyles">
        {{ city.services_count }} {{ declension(city.services_count, ["филиал", "филиала", "филиалов"]) }}
      </div>
    </div>
  </div>
</template>
