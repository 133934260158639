<script setup lang="ts">
import { ref, PropType, onMounted } from "vue"
import { DeviceMedia, YANDEX_METRIKA_GOALS, emitMixpanelEventCardMobilePhotoZoom, emitMixpanelEventCardVideoPlayed, emitYandexMetrika } from "shared/lib"
import { Device } from "shared/api"
import { isArray } from "lodash"
import GLightbox from "glightbox"

// use swiper
import { Swiper, SwiperSlide } from "swiper/vue"
import { Pagination } from "swiper/modules"
import "swiper/css"
import "swiper/css/pagination"

const props = defineProps({
  media: {
    type: Array as PropType<Array<DeviceMedia>>,
    default () { return [] },
    required: true
  },
  device: {
    type: Object as PropType<Device>,
    default () { return {} },
    required: true
  }
})

const videoPlayer = ref<HTMLVideoElement | null>(null)
const stopVideo = () => {
  if (isArray(videoPlayer.value)) {
    videoPlayer.value.forEach(video => {
      video.pause()
    })

    return
  }

  if(videoPlayer.value){
    videoPlayer.value.pause()
  }
}

const openLightbox = (index: number) => {
  emitMixpanelEventCardMobilePhotoZoom()
  emitYandexMetrika(YANDEX_METRIKA_GOALS.CARD_MOBILE_PHOTO_ZOOM)

  const images = props.media.map(item => item.url)

  const lightbox = GLightbox({
    elements: images.map(image => ({
      href: image
    })),
    startAt: index
  })

  lightbox.open()
}

onMounted(() => {
  if (videoPlayer.value && Array.isArray(videoPlayer.value)) {
    videoPlayer.value.forEach((video) => {
      video.addEventListener("play", () => {
        emitMixpanelEventCardVideoPlayed(props.device)
        emitYandexMetrika(YANDEX_METRIKA_GOALS.WATCH_VIDEO)
      })
    })
  }
})
</script>

<template>
  <swiper
    :modules="[Pagination]"
    :slides-per-view="1.1"
    :space-between="5"
    :grab-cursor="true"
    :pagination="{
      type: 'fraction',
    }"
    class="catalog-device-swiper"
    @swiper="() => {}"
    @slide-change="stopVideo"
  >
    <swiper-slide
      v-for="(mediaItem, index) in props.media"
      :key="index"
    >
      <video
        v-if="mediaItem?.video"
        ref="videoPlayer"
        controls
        :poster="mediaItem.url"
        playsinline
      >
        <source
          :src="mediaItem.video.url"
          type="video/mp4"
        >
      </video>
      <img
        v-else
        :src="mediaItem.url"
        loading="lazy"
        :alt="device.FullName"
        @click="openLightbox(index)"
      >
    </swiper-slide>
  </swiper>
</template>

<style>
.catalog-device-swiper .swiper-pagination-fraction {
  position: absolute;
  left: 38%;
  bottom: 36px;
  z-index: 2;
  width: fit-content;
  font-size: 10px;
  font-weight: 400;
  color: #FFFFFF;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  padding: 4px 8px;
}
</style>

<style scoped>
.swiper-slide {
  height: 420px;
  border-radius: 12px;
  overflow: hidden;
}

.swiper-slide video {
  height: 420px;
  width: 100%;
  margin: 0 auto;
  object-fit: cover;
  border-radius: 12px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
