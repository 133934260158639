import { pedantMarketApi } from "../clients"
import type { PassportDataSavingData, PassportDataVerifyingData, PassportDataGettingData, PassportDataRecognizingData, PassportData } from "../types"

const get = (subscriptionOrderId: string) => {
  return pedantMarketApi.get<PassportDataGettingData>(`/customer-passports/get-by-subscription-order/${subscriptionOrderId}`)
}

const save = (data: PassportDataSavingData) => {
  return pedantMarketApi.post("/customer-passports", data)
}

const verify = (data: PassportDataVerifyingData) => {
  return pedantMarketApi.post("/customer-passports/verify", data)
}

const recognize = (data: PassportDataRecognizingData) => {
  return pedantMarketApi.post<{ data: PassportData }>("/customer-passports/recognize", data, {
    "headers": {
      "Content-Type": "multipart/form-data"
    }
  })
}

export const customerPassports = {
  get,
  save,
  verify,
  recognize
}
