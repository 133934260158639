import { cx, cva } from "cva"

const dropdownItemsStyles = cx([
  "absolute",
  "top-[35px]",
  "flex",
  "flex-col",
  "py-[10px]",
  "rounded-xl",
  "bg-bg-main-primary",
  "transition",
  "duration-300"
])

const dropdownItemStyles = cva([
  "!pt-[7px]",
  "!pb-[7px]",
  "!px-6",
  "border-b",
  "border-solid",
  "border-border-primary",
  "!text-base/[130%]",
  "last:border-0",
  "hover:bg-bg-main-thirdly",
  "transition",
  "duration-300"
], {
  variants: {
    disabled: {
      true: [
        "text-disable"
      ]
    }
  }
})

export default {
  dropdownItemsStyles,
  dropdownItemStyles
}
