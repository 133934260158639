<script setup lang="ts">
import classes from "./classes"
import { VueFinalModal } from "vue-final-modal"
import { Button, Icon } from "shared/ui"
import { toRef } from "vue"

interface Props {
  hasLogo?: boolean,
  title?: string,
  titleToLeft?: boolean,
  contentTransition?:string,
  toBottom?: boolean,
  className?: string,
  minHeight?: number,
  blockModal?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    hasLogo: false,
    title: "",
    titleToLeft: false,
    contentTransition: "vfm-fade",
    toBottom: false,
    className: "",
    minHeight: 60,
    blockModal: false
  }
)

const blockModalState = toRef(props, "blockModal")

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: "update:modelValue", modelValue: boolean): void,
    // eslint-disable-next-line no-unused-vars
  (e: "closed"): void
}>()
</script>

<template>
  <VueFinalModal
    :content-transition="!toBottom ? contentTransition : 'vfm-slide-down'"
    :content-class="!toBottom ? classes.modal : classes.bottomModal"
    :swipe-to-close="toBottom ? 'down' : undefined"
    overlay-transition="vfm-fade"
    aria-label="Модальное окно"
    @update:model-value="(val) => emit('update:modelValue', val)"
    @closed="emit('closed')"
  >
    <div
      v-if="blockModalState && toBottom"
      :class="classes.blockModal"
    />

    <div
      :class="[!toBottom ? classes.wrapper : classes.bottomWrapper, `min-h-[${minHeight}vh]`]"
      @click.self="() => emit('update:modelValue', false)"
    >
      <div :class="!toBottom ? classes.content : classes.bottomContent">
        <div
          v-if="!toBottom"
          :class="[classes.header, hasLogo ? classes.headerWithLogo : '']"
        >
          <img
            v-if="hasLogo"
            class="w-[101px] h-[25px]"
            src="/images/logo_white.svg"
            alt="Pedant.Market"
            width="101"
            height="25"
          >
          <h2
            v-if="title"
            :class="[classes.title, titleToLeft ? classes.titleToLeft : '']"
            v-html="title"
          />
          <Button
            :class="classes.button"
            aria-label="Закрыть"
            @click="emit('update:modelValue', false)"
          >
            <Icon
              name="common/cross"
              width="32"
              height="32"
              class="text-ghost"
            />
          </Button>
        </div>
        <div
          v-else-if="title"
          :class="classes.header"
        >
          <h2
            :class="classes.title"
            v-html="title"
          />

          <Button
            :class="classes.button"
            aria-label="Закрыть"
            @click="emit('update:modelValue', false)"
          >
            <Icon
              name="common/cross"
              width="32"
              height="32"
              class="text-ghost"
            />
          </Button>
        </div>
        <div
          v-else-if="toBottom"
          :class="classes.header"
        >
          <Button
            :class="classes.button"
            aria-label="Закрыть"
            @click="emit('update:modelValue', false)"
          >
            <Icon
              name="common/cross"
              width="32"
              height="32"
              class="text-ghost"
            />
          </Button>
        </div>
        <div :class="[classes.main, className]">
          <slot />
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>
