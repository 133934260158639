import { pedantMarketApi } from "../clients"
import type { TinkoffOrderCreationParams, TinkoffSuccessOrderCreation } from "../types"

const createOrder = (params: TinkoffOrderCreationParams) => {
  return pedantMarketApi.post<TinkoffSuccessOrderCreation>("/tinkoff/create-url", params)
}

export const tinkoff = {
  createOrder
}
