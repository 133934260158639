import { cx } from "cva"

const headerStyles = cx([
  "sticky",
  "top-0",
  "z-[95]",
  "h-[97px]",
  "xl:h-[128px]"
])

const headerInnerStyles = cx([
  "bg-bg-blue-accent",
  "xl:max-h-[90px]",
  "py-2.5",
  "xl:py-[22px]",
  "text-primary-on-color"
])

export default {
  headerStyles,
  headerInnerStyles
}
