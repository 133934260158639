import { acceptHMRUpdate, defineStore } from "pinia"
import api, { Device, SubscriptionPercent, PassportData, SubscriptionOrder, Price, ServiceCenter, PassportDataRecognizingData } from "shared/api"
import { getMinCreditPrice, getSubscriptionMonthlyPayment, sendMTSClickId as _sendMTSClickId, getMixpanelId } from "shared/lib"
import { ref, computed } from "vue"
import { useCitiesStore, useUtmStore } from "stores"
import { AxiosError } from "axios"
import { router } from "app/providers"
import { DEFAULT_ISO_DATE, ROUTE_NAMES } from "shared/constants"

export const useSubscriptionStore = defineStore("subscription", {
  state: () => {
    const citiesStore = useCitiesStore()
    const utmStore = useUtmStore()

    const errors = ref<Record<string, string[]>>({})

    const device = ref<Device | null>()
    const temporaryDeviceId = ref<string>("")     // нужен для getPassportData
    const cheaperDevices = ref<Device[] | []>()

    const phone = ref<string | null>()
    const isLoading = ref<boolean>(false)
    const temporaryOrderId = ref<string>("")     // нужен для getPassportData
    const passportData = ref<PassportData | null>()
    const isMatchesRegistrationAddress = ref<boolean>(false)

    const order = ref<SubscriptionOrder | null>()
    const qrCode = ref<string | null>()
    const price = ref<Price | null>()
    const serviceCenter = ref<ServiceCenter | null>()

    const percents = ref<SubscriptionPercent[] | null>()
    const selectedPercent = ref<SubscriptionPercent | null>()

    const formCurrentStep = ref<number>(1)

    const currentStepScanPassportData = ref<number>(1)
    const firstPassportPhoto = ref<File | null>()
    const secondPassportPhoto = ref<File | null>()
    const isSuccessfullyScannedPassportData = ref<boolean>(false)
    const scanErrors = ref<string>("")

    const minCreditPrice = computed(() => getMinCreditPrice(device.value))
    const monthlyPayment = computed(() => getSubscriptionMonthlyPayment(device.value, selectedPercent.value))
    const totalPrice = computed(() => {
      if (!device.value || !selectedPercent.value) return

      const basicPrice = device.value.BasicPrice
      const percent = selectedPercent.value.Percent

      if (!basicPrice || !percent) {
        return
      }

      return Math.floor(basicPrice * percent)
    })

    const addDeviceToSubscription = (newDevice: Device) => device.value = newDevice
    const removeDeviceFromSubscription = () => device.value = null
    const clearSubscription = () => device.value = null
    const isEmptySubscription = computed(() => device.value === null)

    function getPercents() {
      return api.subscriptionPercents.get()
        .then(({data}) => {
          percents.value = data.data
        })
        .catch(() => {
          percents.value = null
        })
    }

    function setDefaultPercent() {
      if (!percents.value) return

      selectedPercent.value = percents.value[2]
    }

    function getCheaperDevices() {
      if (!device.value) return

      api.devices.getList({
        ParentId: device.value.ParentId,
        Models: [device.value.ModelId],
        MaxPrice: device.value.Price
      })
        .then(({data}) => {
          cheaperDevices.value = data.data
        })
        .catch(() => {
          cheaperDevices.value = []
        })
    }

    function createOrder() {
      if (!device.value || !citiesStore.current || !selectedPercent.value || !phone.value || !totalPrice.value) return

      isLoading.value = true

      const payload = {
        FromSite: "PedantMarket",
        ModelPriceId: device.value.ModelPriceId,
        PurchasingActId: device.value.Id,
        CustomerPhone: phone.value,
        UtmTags: utmStore.utmTagsAsString,
        CityId: citiesStore.current.id,
        SubscriptionBasicPriceId: device.value.SubscriptionBasicPriceId,
        SubscriptionMonths: selectedPercent.value.Months,
        SubscriptionPercent: selectedPercent.value.Percent,
        SubscriptionPayment: totalPrice.value,
        BasicPrice: device.value.BasicPrice,
        MixpanelId: getMixpanelId()
      }

      return api.subscriptionOrders.create(payload)
        .then(({data}) => {
          order.value = data
        })
        .catch((error) => {
          order.value = null

          if (error instanceof Error && "isAxiosError" in error) {
            const e = error as AxiosError<{ errors?: Record<string, string[]> }>

            errors.value = e.response?.data?.errors ?? {}

            throw error
          }
        })
        .finally(() => isLoading.value = false)
    }

    function updateOrder() {
      if (!device.value || !selectedPercent.value || !phone.value || !totalPrice.value || !order.value) return

      isLoading.value = true

      const payload = {
        CustomerPhone: phone.value,
        ModelPriceId: device.value.ModelPriceId,
        PurchasingActId: device.value.Id,
        SubscriptionBasicPriceId: device.value.SubscriptionBasicPriceId,
        BasicPrice: device.value.BasicPrice,
        SubscriptionMonths: selectedPercent.value.Months,
        SubscriptionPercent: selectedPercent.value.Percent,
        SubscriptionPayment: totalPrice.value,
        MixpanelId: getMixpanelId()
      } as {
        CustomerPhone: string,
        CustomerName?: string,
        ModelPriceId: string,
        PurchasingActId: string,
        SubscriptionBasicPriceId: string,
        BasicPrice: number,
        SubscriptionMonths: number,
        SubscriptionPercent: number,
        SubscriptionPayment: number,
        MixpanelId: string
      }

      if (passportData.value?.FullName) payload.CustomerName = passportData.value.FullName

      return api.subscriptionOrders.update(order.value.Id, payload)
        .finally(() => isLoading.value = false)
    }

    function getPassportData(subscriptionOrderId: string) {
      isLoading.value = true

      return api.customerPassports.get(subscriptionOrderId)
        .then(({data}) => {
          const handleInputPassportData = () => {
            const prepareDate = (isoDate: string) => {
              if (isoDate === DEFAULT_ISO_DATE) return ""

              const date = new Date(Date.parse(isoDate))

              let day = date.getDate()
              let month = date.getMonth()

              return [`${day < 10 ? "0" : ""}${day}`, `${month < 10 ? "0" : ""}${month}`, date.getFullYear()].join(".")
            }

            const {
              birthDate, dateOfIssue, divisionCode, issuedBy, surname, name, patronymic, seriesAndNumber, placeOfBirth,
              registrationAddressApartment, registrationAddressCity, registrationAddressHouse, registrationAddressRegion, registrationAddressStreet,
              residentialAddressApartment, residentialAddressCity, residentialAddressHouse, residentialAddressRegion, residentialAddressStreet
            } = data.passportData

            passportData.value = {
              FullName: [surname, name, patronymic].join(" ").trim(),
              BirthDate: prepareDate(birthDate),
              SeriesAndNumber: seriesAndNumber,
              DateOfIssue: prepareDate(dateOfIssue),
              IssuedBy: issuedBy,
              DivisionCode: divisionCode,
              PlaceOfBirth: placeOfBirth,
              RegistrationAddressRegion: registrationAddressRegion,
              RegistrationAddressCity: registrationAddressCity,
              RegistrationAddressStreet: registrationAddressStreet,
              RegistrationAddressHouse: registrationAddressHouse,
              RegistrationAddressApartment: registrationAddressApartment,
              ResidentialAddressCity: residentialAddressCity,
              ResidentialAddressRegion: residentialAddressRegion,
              ResidentialAddressStreet: residentialAddressStreet,
              ResidentialAddressHouse: residentialAddressHouse,
              ResidentialAddressApartment: residentialAddressApartment
            } as PassportData
          }

          phone.value = data.customerPhone
          temporaryDeviceId.value = data.deviceId
          temporaryOrderId.value = data.subscriptionOrderId
          handleInputPassportData()
        })
        .catch(error => {
          if (error instanceof Error && "isAxiosError" in error) {
            const e = error as AxiosError<{ errors?: Record<string, string[]> }>

            errors.value = e.response?.data?.errors ?? {}

            throw error
          }
        })
        .finally(() => isLoading.value = false)
    }

    function savePassportData() {
      if (!passportData.value || !order.value) return

      isLoading.value = true

      const payload = {
        SubscriptionOrderId: order.value.Id,
        Signed: false, // заказ пока не подтвержден по смс
        PassportData: passportData.value
      }

      return api.customerPassports.save(payload)
        .catch(error => {
          if (error instanceof Error && "isAxiosError" in error) {
            const e = error as AxiosError<{ errors?: Record<string, string[]> }>

            errors.value = e.response?.data?.errors ?? {}

            throw error
          }
        })
        .finally(() => isLoading.value = false)
    }

    function verifyPassportData(code: string) {
      if (!passportData.value || !order.value) return

      isLoading.value = true

      const payload = {
        SubscriptionOrderId: order.value.Id,
        VerificationCode: code
      }

      return api.customerPassports.verify(payload)
        .catch((error: Error) => {
          throw error
        })
        .finally(() => isLoading.value = false)
    }

    function getDevice(deviceId?: string) {
      return api.devices.getItem(deviceId ?? temporaryDeviceId.value)
        .then(({data}) => {
          device.value = data
        })
        .catch(() => {
          device.value = null
          // redirect if device out of stock
          router.push({name: ROUTE_NAMES.SUBSCRIPTION_OUT_OF_STOCK})
        })
    }

    const getOrder = (orderId?: string) => {
      return api.subscriptionOrders.getItem(orderId ?? temporaryOrderId.value)
        .then(({data}) => {
          order.value = data.data as SubscriptionOrder
          qrCode.value = data.additional.QrCode
          serviceCenter.value = data.relations.service_center
          price.value = data.relations.price
        })
        .catch(async () => {
          order.value = null
          qrCode.value = null
          serviceCenter.value = null
          price.value = null
          await router.push({name: ROUTE_NAMES.NOT_FOUND})
        })
    }

    const changeFormCurrentStep = (stepNumber: number) => formCurrentStep.value = stepNumber

    const sendMTSClickId = async () => {
      if (!order.value) return

      await _sendMTSClickId(order.value.Id, order.value.SubscriptionPayment)
    }

    function scanPassportData() {
      if (!firstPassportPhoto.value || !secondPassportPhoto.value || !order.value) return

      const payload: PassportDataRecognizingData = {
        MainPhoto: firstPassportPhoto.value,
        RegistrationPhoto: secondPassportPhoto.value,
        SubscriptionOrderId: order.value.Id
      }

      return api.customerPassports.recognize(payload)
        .then(({ data }) => {
          passportData.value = data.data
          isSuccessfullyScannedPassportData.value = true
        })
        .catch(error => {
          currentStepScanPassportData.value = 1
          passportData.value = null
          isSuccessfullyScannedPassportData.value = false

          if (error instanceof Error && "isAxiosError" in error) {
            const e = error as AxiosError<{ error?: string }>

            scanErrors.value = e.response?.data?.error ?? ""

            throw error
          }
        })
    }

    function clearScanPassportData() {
      currentStepScanPassportData.value = 1
      firstPassportPhoto.value = null
      secondPassportPhoto.value = null
      passportData.value = null
      scanErrors.value = ""
      isSuccessfullyScannedPassportData.value = false
    }

    return {
      device,
      cheaperDevices,
      minCreditPrice,
      monthlyPayment,
      percents,
      phone,
      order,
      selectedPercent,
      passportData,
      isMatchesRegistrationAddress,
      price,
      serviceCenter,
      qrCode,
      isLoading,
      formCurrentStep,
      currentStepScanPassportData,
      firstPassportPhoto,
      secondPassportPhoto,
      isSuccessfullyScannedPassportData,
      changeFormCurrentStep,
      addDeviceToSubscription,
      removeDeviceFromSubscription,
      clearSubscription,
      isEmptySubscription,
      getPercents,
      getCheaperDevices,
      setDefaultPercent,
      createOrder,
      updateOrder,
      getPassportData,
      savePassportData,
      verifyPassportData,
      errors,
      scanErrors,
      getDevice,
      getOrder,
      sendMTSClickId,
      scanPassportData,
      clearScanPassportData
    }
  },
  persist: {
    storage: localStorage,
    paths: ["device", "order", "phone"]
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(
    useSubscriptionStore,
    import.meta.hot
  ))
}
