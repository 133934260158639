<script setup lang="ts">
import { ref, PropType } from "vue"
import { vOnClickOutside } from "@vueuse/components"
import classes from "./classes"
import { ROUTE_NAMES } from "shared/constants"
import { Button, Icon } from "shared/ui"

interface MenuItem {
  name: string,
  to: string,
  disabled?: boolean,
  target?: string,
  reachGoal: Function
}

defineProps({
  name: {
    type: String,
    default: "",
    required: true
  },
  items: {
    type: Array as PropType<Array<MenuItem>>,
    default() {
      return []
    },
    required: true
  },
  catalogIcon: {
    type: Boolean,
    default: false
  },
  isRightPosition: {
    type: Boolean,
    default: false
  }
})

const isShowItems = ref(false)
const toggleShowItems = () => isShowItems.value = !isShowItems.value
const closeShowItems = () => isShowItems.value = false
</script>

<template>
  <div
    v-on-click-outside="closeShowItems"
    class="relative cursor-pointer"
  >
    <Button
      :class="['hover:text-accent-hover flex flex-row items-center gap-1.5', { 'flex-row-reverse': catalogIcon }]"
      @click="toggleShowItems"
    >
      {{ name }}
      <Icon
        :name="catalogIcon ? 'common/catalog': 'common/chevron'"
        width="11"
        height="8"
        :class="['!text-primary ml-1.5 duration-300', {'rotate-180': isShowItems && !catalogIcon}]"
      />
    </Button>
    <div
      v-show="isShowItems"
      :class="[classes.dropdownItemsStyles, {'right-0': isRightPosition}]"
    >
      <Button
        v-for="(item, index) in items"
        :key="index"
        type="router-link"
        :class="[classes.dropdownItemStyles({ disabled: item.disabled })]"
        :url="Object.values(ROUTE_NAMES).includes(item.to) ? { name: item.to } : item.to"
        :target="item.target ?? ''"
        @click="closeShowItems(), item.reachGoal()"
      >
        {{ item.name }}
      </Button>
    </div>
  </div>
</template>
