import { pedantMarketApi } from "../clients"
import type { Paginated, Review, ReviewsListParams, ReviewAddingData } from "../types"

const getList = ({
  page = 1,
  sort_direction = "desc",
  ...params
}: ReviewsListParams) => {
  return pedantMarketApi.get<Paginated<Review>>("/reviews", {
    params: {
      ...params,
      page,
      sort_direction
    }
  })
}

const add = (data: ReviewAddingData) => {
  return pedantMarketApi.post("/reviews", data, {
    "headers": {
      "Content-Type": "multipart/form-data"
    }
  })
}

export const reviews = {
  getList,
  add
}
