<script setup lang="ts">
import classes from "./classes"
import { ref, PropType, computed, onMounted } from "vue"
import { useBreakpoints } from "@vueuse/core"
import { checkIsNew, loadDeviceImage, numberSpace, skeletonStyles } from "shared/lib"
import { Device } from "shared/api"
import { DeviceGridV3 } from ".."
import { EMIT_MODAL_FUNCTION_NAMES } from "shared/constants"

const breakpoints = useBreakpoints({ xl: 1200 })
const xlAndLarger  = breakpoints.greaterOrEqual("xl")

const props = defineProps({
  device: {
    type: Object as PropType<Device>,
    default () { return {} },
    required: true
  },
  monthlyPayment: {
    type: Number,
    required: true,
    default: 0
  }
})

const isLoadingImage = ref<boolean>(false)
const imageSrc = ref<string>("")
const isNewDevice = computed(() => checkIsNew(props.device))
const deviceFullName = computed(() => `${props.device.FullName}${!isNewDevice.value ? ", б/у" : "" }`)

onMounted(() => loadDeviceImage(props.device, isLoadingImage, imageSrc))

const emit = defineEmits([
  EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_SPECIFICATIONS
])
</script>

<template>
  <div :class="classes.wrapper">
    <div
      v-if="isLoadingImage"
      :class="[classes.image, skeletonStyles]"
    />
    <img
      v-else
      :class="classes.image"
      :src="imageSrc"
      :width="xlAndLarger ? 282 : 130"
      :height="xlAndLarger ? 377 : 160"
      :alt="deviceFullName"
    >
    <div :class="classes.content">
      <div
        :class="classes.deviceName"
        :data-product-id="device.Id"
      >
        {{ deviceFullName }}
      </div>
      <div
        v-if="!xlAndLarger"
        :class="classes.price"
      >
        <div>
          Всего
        </div>
        <div
          v-if="monthlyPayment"
          :class="classes.pricePayment"
        >
          {{ numberSpace(monthlyPayment) }}
          <span :class="classes.pricePaymentSpan">₽/мес</span>
        </div>
      </div>
      <DeviceGridV3
        v-else
        :device="device"
        :is-subscription-page="true"
        @open-modal-show-specifications="emit(EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_SPECIFICATIONS)"
      />
    </div>
  </div>
</template>
