<script setup lang="ts">
import { computed } from "vue"
import { ROUTE_NAMES } from "shared/constants"
import { useModelGroupsStore } from "stores"
import { storeToRefs } from "pinia"
import { Button } from "shared/ui"
import classes from "./classes"

const modelGroupsStore = useModelGroupsStore()
const { list: categories } = storeToRefs(modelGroupsStore)

const firstRowLinks = computed(() => {
  return categories.value.map(category => {
    return {
      name: category.name,
      to: category.url,
      external: false,
      disabled: !category.models.length
    }
  })
})

const secondRowLinks = [
  {
    name: "Контакты",
    to: ROUTE_NAMES.CONTACTS
  },
  {
    name: "О компании",
    to: ROUTE_NAMES.COMPANY
  },
  {
    name: "Публичная оферта",
    to: "/ofertaPM",
    target: "_blank"
  },
  {
    name: "Договор (оферта) аренды оборудования",
    to: "/oferta_podpiska",
    target: "_blank"
  },
  {
    name: "Реквизиты компании",
    to: ROUTE_NAMES.COMPANY_DETAILS
  },
  {
    name: "Политика по обработке персональных данных",
    to: "/politics",
    target: "_blank"
  }
]

const thirdRowLinks = [
  {
    name: "Как оплатить и получить",
    to: ROUTE_NAMES.PAYMENT,
    external: false
  },
  {
    name: "Личный кабинет",
    to: "https://lk.pedant.market",
    external: true,
    disabled: false
  },
  {
    name: "Отмена подписки",
    to: "https://lk.pedant.market",
    external: true,
    disabled: false
  },
  {
    name: "Гарантия",
    to: ROUTE_NAMES.GUARANTEE,
    external: false
  },
  {
    name: "Возврат",
    to: ROUTE_NAMES.RETURN,
    external: false
  },
  {
    name: "Адреса СЦ для гарантийного ремонта",
    to: ROUTE_NAMES.GARANTIA_SERVICE,
    external: false
  }
]

</script>

<template>
  <div :class="classes.wrapper">
    <ul
      :class="classes.boldLinksWrapper"
    >
      <li
        v-for="(link, index) in firstRowLinks"
        :key="index"
      >
        <Button
          type="router-link"
          intent="link-on-color-secondary"
          underline="off"
          :class="[classes.boldLinks, link.disabled ? classes.unavailableLink : '']"
          :url="Object.values(ROUTE_NAMES).includes(link.to) ? { name: link.to } : link.to"
        >
          {{ link.name }}
        </Button>
      </li>
    </ul>
    <ul
      :class="classes.smallLinksWrapper"
    >
      <li
        v-for="(link, index) in secondRowLinks"
        :key="index"
      >
        <Button
          type="router-link"
          intent="link-on-color-secondary"
          underline="off"
          :url="Object.values(ROUTE_NAMES).includes(link.to) ? { name: link.to } : link.to"
          :class="classes.smallLinks"
          :target="link.target ?? ''"
        >
          {{ link.name }}
        </Button>
      </li>
    </ul>
    <ul
      :class="classes.smallLinksWrapper"
    >
      <li
        v-for="(link, index) in thirdRowLinks"
        :key="index"
      >
        <Button
          :type="link.external ? 'a' : 'router-link'"
          intent="link-on-color-secondary"
          underline="off"
          :url="Object.values(ROUTE_NAMES).includes(link.to) ? { name: link.to } : link.to"
          :href="link.external ? link.to : ''"
          :class="classes.smallLinks"
        >
          {{ link.name }}
        </Button>
      </li>
    </ul>
  </div>
</template>
