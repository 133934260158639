<script setup lang='ts'>
import { cx } from "cva"
import { computed } from "vue"
import InlineSvg from "vue-inline-svg"

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  width: {
    type: [String, Number],
    required: true
  },
  height: {
    type: [String, Number],
    required: true
  },
  color: {
    type: String,
    default: ""
  },
  className: {
    type: String,
    default: ""
  }
})

const processedSrc = computed(() => `/icons/${props.name}.svg`)

const iconStyle = cx([
  "select-none",
  "inline-block",
  "box-content"
])

const iconStyleObject = computed(() => ({
  color: props.color, fill: props.color
}))

const transform = (svg: SVGElement) => {
  const addUniqueIds = (svg: SVGElement) => {
    const idsMatches = svg.innerHTML.match(/id="(.*?)"/g)
    if (!idsMatches) return svg

    const ids = idsMatches
      .map((val) => val.replace(/id="/g, "").replace(/"/g, ""))

    const uniqueId = Math.random().toString(36).replace("0.", "")

    ids.forEach(id => {
      const newId = `${uniqueId}_${id}`
      const regex = new RegExp(`id="${id}"`, "g")
      svg.innerHTML = svg.innerHTML.replace(regex, `id="${newId}"`)
      svg.innerHTML = svg.innerHTML.replace(new RegExp(`#${id}`, "g"), `#${newId}`)
    })

    return svg
  }

  return addUniqueIds(svg)
}
</script>

<template>
  <div
    :class="[iconStyle, iconStyleObject, className]"
  >
    <inline-svg
      :transform-source="transform"
      :width="width"
      :height="height"
      :src="processedSrc"
      focusable="false"
      aria-hidden="true"
    />
  </div>
</template>
