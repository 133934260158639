import { cx } from "cva"

const wrapper = cx([
  "relative"
])

const subtitle = cx([
  "mb-5",
  "xl:mb-[34px]"
])

const content = cx([
  "grid",
  "grid-rows-[auto]",
  "xl:grid-cols-[350px_auto]",
  "xl:grid-rows-none"
])

const contentVertical = cx([
  "xl:!grid-rows-[repeat(2,_auto)]]",
  "xl:!grid-cols-none"
])

const contentPanelList = cx([
  "max-h-[200px]",
  "overflow-y-auto",
  "mb-[30px]",
  "xl:max-h-[360px]",
  "xl:mb-0",
  "xl:mr-[15px]"
])

const contentPanelItem = cx([
  "flex",
  "items-start",
  "mb-6"
])

export default {
  wrapper,
  subtitle,
  content,
  contentVertical,
  contentPanelList,
  contentPanelItem
}
