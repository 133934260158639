<script setup lang="ts">
import { EMIT_MODAL_FUNCTION_NAMES } from "shared/constants"
import classes from "./classes"
import { Button, Icon } from "shared/ui"

const emit = defineEmits([
  EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SUBSCRIPTION_HOW_WORKS
])
</script>

<template>
  <Button
    :class="classes.button"
    @click="emit(EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SUBSCRIPTION_HOW_WORKS)"
  >
    <span>Как работает подписка?</span>
    <Icon
      name="common/chevron"
      width="11"
      height="11"
      class-name="text-secondary -rotate-90"
    />
  </Button>
</template>
