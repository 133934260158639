import { yandexSearchApi } from ".."

const search = (text: string, page: number) => {
  return yandexSearchApi.get("", {
    params: {
      apikey: import.meta.env.APP_YANDEX_SEARCH_API_KEY,
      searchid: 6496644,
      text: text,
      per_page: 15,
      page
    }
  })
}

export const yandex = {
  search
}
