import { acceptHMRUpdate, defineStore } from "pinia"
import { ref } from "vue"

import api, { type Review, type ReviewsListParams,
  type PaginatedMeta, type PaginatedLinks,
  type ReviewAddingData } from "shared/api"

export const useReviewsStore = defineStore("reviews", () => {
  const list = ref<Review[]>([])
  const meta = ref<PaginatedMeta>()
  const links = ref<PaginatedLinks>()

  function getList(payload: ReviewsListParams = { page: 1, sort_by: "LikesCount", sort_direction: "desc"}) {
    api.reviews.getList(payload)
      .then(({ data }) => {
        list.value = data.data
        meta.value = data.meta
        links.value = data.links
      })
      .catch(() => {
        list.value = []
      })
  }

  function addReview(payload: ReviewAddingData) {
    api.reviews.add(payload)
      .catch(() => {})
  }

  return {
    list,
    meta,
    links,
    getList,
    addReview
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useReviewsStore, import.meta.hot))
}
