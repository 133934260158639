import { pedantMarketApi } from "../clients"
import type { OrderData, OrderCreationData } from "../types"

const create = (data: OrderCreationData) => {
  return pedantMarketApi.post("/orders", data)
}

const getItem = (orderId: string) => {
  return pedantMarketApi.get<OrderData>(`/orders/${orderId}`)
}

const sendVerificationCode = (orderId: string) => {
  return pedantMarketApi.post("/orders/send-verification-code", { Id: orderId })
}

const verifyVerificationCode = (orderId: string, code: string) => {
  return pedantMarketApi.post("/orders/verify-verification-code", { Id: orderId, Code: code })
}

export const orders = {
  create,
  getItem,
  sendVerificationCode,
  verifyVerificationCode
}
