const wrapper = [
  "flex",
  "xl:mb-14"
]

const image = [
  "w-[104px]",
  "h-[138px]",
  "rounded-xl",
  "mr-3.5",
  "xl:mr-8",
  "xl:w-[282px]",
  "xl:h-[377px]"
]

const deviceName = [
  "font-bold",
  "text-primary",
  "leading-[18px]",
  "mb-3.5",
  "xl:text-[36px]/[40px]",
  "xl:mb-6"
]

const price = [
  "grid",
  "grid-rows-[repeat(2,_auto)]",
  "xl:grid-rows-auto",
  "xl:grid-cols-[repeat(2,_min-content)]",
  "xl:whitespace-nowrap",
  "xl:items-center",
  "xl:gap-[7px]",
  "xl:text-[24px]/[31px]",
  "xl:my-4"
]

const title = [
  "text-[16px]/[11px]",
  "font-bold",
  "text-primary",
  "xl:text-[24px]"
]

const pricePayment = [
  "text-[32px]",
  "font-bold",
  "text-primary",
  "xl:text-[24px]"
]

const pricePaymentSpan = [
  "text-[24px]"
]

const content = [
  "flex-1",
  "pt-1",
]

export default {
  wrapper,
  image,
  deviceName,
  price,
  title,
  pricePayment,
  pricePaymentSpan,
  content
}
