const text = [
  "text-[10px]/[120%]",
  "xl:text-[11px]/[130%]",
  "text-secondary-on-color",
  "opacity-40"
]

export default {
  text
}
