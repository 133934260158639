const wrapper = [
  "p-4",
  "bg-main-blue-100",
  "rounded-xl",
  "xl:hidden"
]

const title = [
  "text-primary",
  "text-[18px]/[120%]",
  "font-bold",
  "mb-1.5"
]

export default {
  wrapper,
  title
}
