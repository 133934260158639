import { pedantMarketApi } from "../clients"
import type { City } from "../types"

const getList = () => {
  return pedantMarketApi.get<City[]>("/cities")
}

export const cities = {
  getList
}
