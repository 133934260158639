<script setup lang="ts">
import classes from "./classes"
import { cva, type VariantProps } from "cva"
import { ref, computed } from "vue"
import { Icon, InputText } from ".."

import VueDatePicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"
import type { DatePickerInstance } from "@vuepic/vue-datepicker"

const input = cva([], {
  variants: {
    intent: {
      primary: classes.primary
    },
    type: {
      input: classes.input
    }
  }
})

type InputTextProps = VariantProps<typeof input>

interface Props {
  modelValue?: string,
  intent: InputTextProps["intent"],
  type: InputTextProps["type"],
  id: string,
  name?: string,
  placeholder?: string,
  error?: string,
  className?: string,
  required?: boolean,
  max?: string
}

const props = withDefaults(
  defineProps<Props>(),
  {
    modelValue: "",
    intent: "primary",
    type: "input",
    id: "",
    name: "",
    placeholder: "",
    error: "",
    className: "",
    required: false,
    max: ""
  }
)

const emit = defineEmits(["update:modelValue"])

const inputValue = computed({
  get() {
    return props.modelValue
  },

  set(value) {
    emit("update:modelValue", value)
  }
})

const datepicker = ref<DatePickerInstance>(null)

const closeCalendar = () => {
  if (datepicker.value) {
    datepicker.value.closeMenu()
  }
}
</script>

<template>
  <VueDatePicker
    :id="id"
    ref="datepicker"
    v-model="inputValue"
    position="center"
    text-input
    :max-range="max"
    model-type="dd.MM.yyyy"
    format="dd.MM.yyyy"
    locale="ru"
    cancel-text="Отменить"
    select-text="Выбрать"
    :enable-time-picker="false"
    :action-row="{ showSelect: true, showCancel: true, showNow: false, showPreview: true }"
    :name="name"
    :placeholder="placeholder"
    :required="required"
    :clearable="false"
  >
    <template #dp-input>
      <InputText
        :id="id"
        v-model="inputValue"
        type="input"
        :name="name ?? id"
        :placeholder="placeholder"
        :error="error"
        :max="max"
        autocomplete="off"
        intent="primary"
        mask="##.##.####"
        @keydown.enter="closeCalendar"
      />
    </template>
    <template
      v-if="!error"
      #input-icon
    >
      <Icon
        name="common/calendar"
        width="24"
        height="24"
      />
    </template>
  </VueDatePicker>
</template>

<style>
.dp__theme_light,
.dp__theme_dark {
  --dp-border-color: #E6E6E6;
  --dp-border-color-hover: #023E7F;
  --dp-input-padding: 19px 16px;
  --dp-input-icon-padding: 16px;
}

.dp__input {
  border-width: 2px;
}

.dp__input_icon {
  display: flex;
  inset-inline-start: auto;
  inset-inline-end: 0;
  margin-right: 16px;
}
</style>
