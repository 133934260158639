<script setup lang="ts">
import { computed } from "vue"
import otherLinks from "./links"
import classes from "./classes"
import { Button, Icon } from "shared/ui"
import { useModelGroupsStore } from "stores"
import { storeToRefs } from "pinia"
import { MODEL_GROUP_NAME, ROUTE_NAMES } from "shared/constants"
import { YANDEX_METRIKA_GOALS, emitYandexMetrika } from "shared/lib"

interface FooterMainMenuItem {
  name: string,
  to: string,
  external: boolean,
  disabled: boolean,
  target?: string
}

const modelGroupsStore = useModelGroupsStore()
const { list: categories } = storeToRefs(modelGroupsStore)

const links = computed(() => {
  const catalogItems = categories.value.map(category => {
    return {
      name: category.name,
      to: category.url,
      external: false,
      disabled: !category.models.length
    } as FooterMainMenuItem
  })

  return [
    {
      name: "Каталог товаров",
      items: catalogItems
    },
    ...otherLinks
  ]
})

const handleClick = (item: FooterMainMenuItem) => {
  item.name === MODEL_GROUP_NAME.IPHONE && (emitYandexMetrika(YANDEX_METRIKA_GOALS.CATALOG_IPHONE), emitYandexMetrika(YANDEX_METRIKA_GOALS.CATALOG_IPHONE_IZ_FOOTERA))
}
</script>

<template>
  <div :class="classes.wrapper">
    <ul
      v-for="link in links"
      :key="link.name"
    >
      <li :class="classes.category">
        {{ link.name }}
        <ul class="mt-4">
          <li
            v-for="(item, index) in link.items"
            :key="index"
            class="mb-3 last:mb-0"
          >
            <Button
              :type="item.external ? 'a' : 'router-link'"
              :href="item.external ? item.to : ''"
              :url="Object.values(ROUTE_NAMES).includes(item.to) ? { name: item.to } : item.to"
              :class="[classes.links, 'group', item.disabled ? classes.unavailableLink : '']"
              :target="item.target ?? ''"
              @click="handleClick(item)"
            >
              <Icon
                v-if="item.name === 'Калькулятор Trade-In'"
                name="common/trade-in"
                width="20"
                height="20"
                class="-ml-[3px] mr-[2px] group-hover:animate-spin-left"
              />
              {{ item.name }}
            </Button>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
