import { paymentCardTitle, paymentCardButton }  from "shared/lib"

const wrapper = [
  "rounded-xl",
  "shadow-xl",
  "flex",
  "flex-col",
  "items-start",
  "py-4",
  "px-6",
  "xl:p-5",
  "bg-icon-primary-on-color"
]

const header = [
  "flex",
  "flex-col",
  "mb-3"
]

const title = [
  ...paymentCardTitle,
  "order-2"
]

const stopper = [
  "h-[17px]",
  "order-1",
  "!py-[4px]",
  "!px-[12px]",
  "mb-[4px]"
]

const total = [
  "w-full"
]

const titleBig = [
  "text-[36px]/[43px]",
  "font-bold",
  "text-primary"
]

const button = [
  ...paymentCardButton
]

const link = [
  "justify-center",
  "mt-3",
  "inline-block",
  "w-full",
  "xl:justify-start",
  "xl:ml-[38px]",
  "text-[14px]/[21px]",
  "xl:text-[16px]/[24px]"
]

export default {
  wrapper,
  header,
  title,
  stopper,
  total,
  titleBig,
  button,
  link
}
