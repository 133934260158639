import { cx } from "cva"

const wrapper = cx([
  "grid",
  "auto-rows-auto",
  "gap-y-[27px]"
])

const boldLinksWrapper = cx([
  "grid",
  "grid-flow-row",
  "gap-y-[3px]"
])

const boldLinks = cx([
  "text-base/[120%]",
  "font-bold"
])

const unavailableLink = cx([
  "opacity-60",
  "pointer-events-none"
])

const smallLinksWrapper = cx([
  "grid",
  "grid-flow-row",
  "gap-y-[5px]"
])

const smallLinks = cx([
  "!text-sm/[21px]"
])

export default {
  wrapper,
  boldLinksWrapper,
  boldLinks,
  smallLinksWrapper,
  smallLinks,
  unavailableLink
}
