import { pedantMarketApi } from "../clients"
import type { ModelColor, ModelColorListParams } from "../types"

const getList = (params?: ModelColorListParams) => {
  return pedantMarketApi.get<ModelColor[]>("/model-colors", {
    params: {
      ...params
    }
  })
}

export const modelColors = {
  getList
}
