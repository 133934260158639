<script setup lang="ts">
import classes from "./classes"
import { declension, linkStyles } from "shared/lib"
import { useCitiesStore } from "stores"
import { storeToRefs } from "pinia"
import { EMIT_MODAL_FUNCTION_NAMES } from "shared/constants"

const citiesStore = useCitiesStore()
const { current: currentCity } = storeToRefs(citiesStore)

const emit = defineEmits([
  EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_SERVICE_CENTERS
])
</script>

<template>
  <div
    v-if="currentCity"
    :class="[classes.wrapper, 'container']"
  >
    <div :class="classes.item">
      <div :class="classes.itemTitle">
        Выкупим быстро, без торга
      </div>
      <div>
        Мы не будем торговаться, деньги получаете сразу
      </div>
    </div>
    <div :class="classes.item">
      <div :class="classes.itemTitle">
        Выкупаем только смартфон
      </div>
      <div>
        Коробка, чеки и аксессуары не нужны
      </div>
    </div>
    <div :class="classes.item">
      <div :class="classes.itemTitle">
        {{ currentCity.services_count }}
        {{ declension(currentCity.services_count, ["филиал", "филиала", "филиалов"]) }}
        в городе
        <span
          :class="[linkStyles, 'whitespace-nowrap']"
          @click="emit(EMIT_MODAL_FUNCTION_NAMES.OPEN_MODAL_SHOW_SERVICE_CENTERS)"
        >
          {{ currentCity.name }} ▼
        </span>
      </div>
      <div>
        Удобное расположение
        {{ currentCity.services_count === 1 ? 'салона' : 'салонов, выбирайте любой' }}
      </div>
    </div>
  </div>
</template>
