// когда оставил заявку на подписку (ввел номер)
export function emitTopMailSubscriptionOrderCreated () {
  window._tmr.push({ type: 'reachGoal', id: 3578826, goal: 'number'});
}

// когда отправил заявку с паспортом
export function emitTopMailSubscriptionOrderWithPassportData () {
  window._tmr.push({ type: 'reachGoal', id: 3578826, goal: 'passport'});
}

// когда подтвердил заявку по СМС
export function emitTopMailSubscriptionOrderVerified () {
  window._tmr.push({ type: 'reachGoal', id: 3578826, goal: 'passport_and_sms'});
}
