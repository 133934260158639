import { cx } from "cva"

const settingsButton = cx([
  "flex",
  "items-center",
  "gap-x-2",
  "w-max",
  "text-sm"
])

export default {
  settingsButton
}
