import { acceptHMRUpdate, defineStore } from "pinia"
import { ref } from "vue"

import api from "shared/api"
import { PHONE_VERIFICATION_CONTEXT } from "shared/constants"

export const usePhoneVerificationStore = defineStore("phone-verification", () => {
  const resendTimer = ref<number>(0)
  const resendEnabled = ref<boolean>(true)
  const verificationCode = ref<string | null >(null)
  const isVerificationFailed = ref<boolean | null>(null)
  const isLoading = ref<boolean>(false)

  const phone = ref<string | null> (null)
  const orderId = ref<string>("")

  const setOrderId = (newOrderId: string) => orderId.value = newOrderId
  const setPhone = (newPhone: string) => phone.value = newPhone

  const sendVerificationCode = async (context: string) => {
    if (!orderId.value) return

    let response = null

    if (context === PHONE_VERIFICATION_CONTEXT.ORDER) {
      response = await api.orders.sendVerificationCode(orderId.value)
    } else if (context === PHONE_VERIFICATION_CONTEXT.SUBSCRIPTION_ORDER) {
      response = await api.subscriptionOrders.sendVerificationCode(orderId.value)
    }

    if(!response) return

    if (response.status === 200) {
      // Verification code sent successfully
      resendTimer.value = 600 // 10 мин
      resendEnabled.value = false
      const countdownInterval = setInterval(() => {
        if (resendTimer.value) {
          resendTimer.value -= 1
        } else {
          clearInterval(countdownInterval)
          resendEnabled.value = true
        }
      }, 1000)
    }
  }

  const verifyPhoneNumber = async(context: string, code: string) => {
    if (!orderId.value) return

    isLoading.value = true

    let request = null
    if (context === PHONE_VERIFICATION_CONTEXT.ORDER) {
      request = api.orders.verifyVerificationCode(orderId.value, code)
    } else if (context === PHONE_VERIFICATION_CONTEXT.SUBSCRIPTION_ORDER) {
      const timeZoneOffsetInHours = new Date().getTimezoneOffset() / 60
      const timeLocal = new Date().toLocaleString('en-GB', { timeZone: 'UTC' }).replace(',', '').replaceAll('/', '.')

      request = api.subscriptionOrders.verifyVerificationCode(orderId.value, code, timeZoneOffsetInHours, timeLocal)
    }

    if(!request) return

    await request
      .then(() => isVerificationFailed.value = false)
      .catch(() => isVerificationFailed.value = true)
      .finally(() => isLoading.value = false)
  }

  return {
    resendTimer,
    resendEnabled,
    verificationCode,
    isVerificationFailed,
    isLoading,
    sendVerificationCode,
    verifyPhoneNumber,
    orderId,
    setOrderId,
    phone,
    setPhone
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePhoneVerificationStore, import.meta.hot))
}
