<script setup lang="ts">
import classes from "./classes"
import { PropType, computed } from "vue"
import { Device } from "shared/api"
import { Button, Icon } from "shared/ui"
import { useUserStore } from "stores"

const props = defineProps({
  device: {
    type: Object as PropType<Device>,
    default () { return {} },
    required: true
  }
})

const userStore = useUserStore()

const isFavouriteDevice = computed(() => userStore.isFavouriteDevice(props.device))
</script>

<template>
  <Button
    :title="!isFavouriteDevice ? 'Добавить товар в избранное' : 'Убрать товар из избранного'"
    @click="userStore.toggleFavouriteDevice(props.device)"
  >
    <Icon
      name="common/like-white"
      width="20"
      height="18"
      :class="[classes.root, isFavouriteDevice ? classes.active : '']"
    />
  </Button>
</template>
