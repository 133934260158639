const peculiarities = "mb-4"

const title = [
  "relative",
  "mb-3",
  "xl:mb-4",
  "font-bold",
  "text-primary"
]

const item = [
  "flex",
  "items-start",
  "mb-4",
  "text-[16px]/[120%]"
]

const itemName = [
  "font-bold",
  "text-primary",
]

const itemValue = [
  "flex",
  "items-center",
  "ml-1"
]

const itemValueIcon = "ml-[5px]"

const description = [
  "xl:mb-[25px]"
]

const descriptionContent = [
  "mb-[25px]",
  "xl:mb-0"
]

const descriptionContentText = [
  "inline-block",
  "overflow-hidden"
]

const specifications = [
  "mt-3",
  "xl:mt-0"
]

const deviceFilters = [
  "grid",
  "row-[repeat(2,_auto)]",
  "gap-4"
]

const deviceFiltersMemory = [
  "grid",
  "row-[repeat(2,_auto)]",
  "gap-2"
]

const deviceFiltersName = [
  "leading-[16px]",
  "[&>span]:font-bold",
  "[&>span]:text-primary",
  "xl:leading-[20px]"
]

const deviceFiltersMemoryList = [
  "grid",
  "gap-[5px]"
]

const deviceFiltersMemoryItem = [
  "transition",
  "duration-300",
  "bg-bg-main-thirdly",
  "rounded-[70px]",
  "leading-[16px]",
  "text-primary",
  "!py-2.5",
  "!px-4",
  "whitespace-nowrap",
  "xl:leading-[20px]",
  "enabled:hover:bg-bg-blue-accent-hover",
  "enabled:active:bg-bg-blue-accent-click",
  "enabled:hover:text-primary-on-color",
  "disabled:opacity-50"
]

const deviceFiltersMemoryItemSelected = [
  "!bg-bg-blue-accent",
  "text-primary-on-color"
]

const deviceFiltersColor = [
  "grid",
  "row-[repeat(2,_auto)]",
  "gap-2"
]

const deviceFiltersColorList = [
  "grid",
  "gap-[5px]"
]

const deviceFiltersColorItem = [
  "!w-[32px]",
  "h-[32px]",
  "rounded-[50%]",
  "transition",
  "duration-300",
  "!border",
  "!border-solid",
  "!border-border-active",
  "enabled:hover:shadow-[inset_0_0_0_2px_#fff,_0_0_1px_2px_#0C5BB9]",
  "enabled:hover:!border-0",
  "disabled:opacity-50"
]

const deviceFiltersColorItemSelected = [
  "shadow-[inset_0_0_0_2px_#fff,_0_0_1px_2px_#0C5BB9]",
  "!border-0"
]

export default {
  peculiarities,
  title,
  item,
  itemName,
  itemValue,
  itemValueIcon,
  description,
  descriptionContent,
  descriptionContentText,
  specifications,
  deviceFilters,
  deviceFiltersMemory,
  deviceFiltersName,
  deviceFiltersMemoryList,
  deviceFiltersMemoryItem,
  deviceFiltersMemoryItemSelected,
  deviceFiltersColor,
  deviceFiltersColorList,
  deviceFiltersColorItem,
  deviceFiltersColorItemSelected
}
