import { pedantMarketApi } from "../clients"
import type { ServiceCenter } from "../types"

const getList = (city_id: string) => {
  return pedantMarketApi.get<ServiceCenter[]>("/service-centers", {
    params: { city_id }
  })
}

export const serviceCenters = {
  getList
}
