import { cx } from "cva"
import { socialButtonTelegramStyles, socialButtonWaStyles } from "shared/lib"

const root = cx([
  "flex",
  "justify-between",
  "items-center",
  "py-[15px]",
  "px-5",
  "rounded-[17px]",
  "bg-bg-main-thirdly"
])

const title = cx([
  "text-[21px]/[27px]",
  "font-bold",
  "text-primary"
])

const hotline = cx([
  "flex",
  "mt-2",
  "text-[14px]"
])

const hotlineTitle =cx([
  "pr-1"
])

const hotlinePhone =cx([
  "!text-primary",
  "hover:!text-primary"
])

const socialButtons = cx([
  "flex",
  "flex-col"
])

const socialButton = cx([
  "flex",
  "items-center",
  "justify-center",
  "!w-[36px]",
  "!h-[36px]",
  "rounded-[50%]"
])

const socialButtonTelegram= [
  "mb-2",
  ...socialButtonTelegramStyles
]

const socialButtonWhatsapp= [
  ...socialButtonWaStyles
]

export default {
  root,
  title,
  hotline,
  hotlineTitle,
  hotlinePhone,
  socialButtons,
  socialButton,
  socialButtonTelegram,
  socialButtonWhatsapp
}
