import { pedantMarketApi } from "../clients"
import { OrderData, SubscriptionOrder, type SubscriptionOrderCreationData, type SubscriptionOrderUpdatingData } from "../types"

const create = (data: SubscriptionOrderCreationData) => {
  return pedantMarketApi.post<SubscriptionOrder>("/subscription-orders", data)
}

const update = (orderId: string, data: SubscriptionOrderUpdatingData) => {
  return pedantMarketApi.patch<SubscriptionOrder>(`/subscription-orders/${orderId}`, data)
}

const getItem = (orderId: string) => {
  return pedantMarketApi.get<OrderData>(`/subscription-orders/${orderId}`)
}

const sendVerificationCode = (orderId: string) => {
  return pedantMarketApi.post("/subscription-orders/send-verification-code", { OrderId: orderId })
}

const verifyVerificationCode = (orderId: string, code: string, timezone: number, timeLocal: string) => {
  return pedantMarketApi.post("/subscription-orders/verify-verification-code", { OrderId: orderId, Code: code, Timezone: timezone, TimeLocal: timeLocal})
}

export const subscriptionOrders = {
  create,
  update,
  getItem,
  sendVerificationCode,
  verifyVerificationCode
}
