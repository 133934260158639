import { acceptHMRUpdate, defineStore } from "pinia"
import { ref } from "vue"

import api, { ModelMemory } from "shared/api"

export const useModelMemoriesStore = defineStore("model-memories", () => {
  const list = ref<ModelMemory[]>([])

  function getList() {
    return api.modelMemories.getList()
      .then(({ data }) => {
        list.value = data
      })
      .catch(() => {
        list.value = []
      })
  }

  return {
    list,
    getList
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useModelMemoriesStore, import.meta.hot))
}
