import { pedantMarketApi } from "../clients"
import type { Organization } from "../types"

const getList = () => {
  return pedantMarketApi.get<Organization[]>("/organizations")
}

export const organizations = {
  getList
}
