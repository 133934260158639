import { pedantMarketApi } from "../clients"
import type { ModelGroup } from "../types"

const get = (slug: string) => {
  return pedantMarketApi.get<ModelGroup>(`/model-groups/${slug}`)
}

const getList = () => {
  return pedantMarketApi.get<ModelGroup[]>("/model-groups")
}

export const modelGroups = {
  get,
  getList
}
