<script setup lang="ts">
import { useSubscriptionStore } from "stores"
import classes from "./classes"
import { Icon, Button } from "shared/ui"
import { storeToRefs } from "pinia"
import { computed } from "vue"

const subscriptionStore = useSubscriptionStore()
const { isSuccessfullyScannedPassportData, currentStepScanPassportData } = storeToRefs(subscriptionStore)

const emit = defineEmits(["openModal"])

const openModal = (event: Event) => {
  event.preventDefault()

  if (isSuccessfullyScannedPassportData.value) {  // начали повторное сканирование
    subscriptionStore.clearScanPassportData()
  }

  emit("openModal")
}

const isLoadingStep = computed(() => currentStepScanPassportData.value === 3)
</script>

<template>
  <div :class="classes.wrapper">
    <div v-if="isSuccessfullyScannedPassportData">
      <Icon
        name="common/check-rounded"
        width="32"
        height="32"
        class="mb-2.5"
      />
      <h3 :class="classes.title">
        Данные паспорта успешно отсканированы
      </h3>
      <p class="mb-4">
        Если данные некорректны, вы можете их исправить вручную или загрузить фото еще раз
      </p>
      <Button
        intent="base-secondary"
        size="middle"
        @click="openModal"
      >
        Загрузить ещё раз
      </Button>
    </div>
    <div v-else>
      <Icon
        name="common/scan"
        width="36"
        height="36"
        class="mb-2.5"
      />
      <h3 :class="classes.title">
        Загрузите фото паспорта
      </h3>
      <p class="mb-4">
        Чтобы заполнить форму автоматически, это быстро и безопасно
      </p>
      <Button
        intent="base-secondary"
        size="middle"
        :disabled="isLoadingStep"
        @click="openModal"
      >
        Загрузить
      </Button>
    </div>
  </div>
</template>
