import { pedantMarketApi } from "../clients"
import type { Redirect } from "../types"

const get = (slug: string) => {
  return pedantMarketApi.get<Redirect>(`/redirects/${slug}`)
}

export const redirects = {
  get
}
