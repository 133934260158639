import { dadataApi } from "../clients"
import type { DadataFullName, DadataIpLocateAddress, DadataAddress, DadataIssuedBy } from "../types.ts"

const getAddressByIp = () => {
  return dadataApi.get<DadataIpLocateAddress>("/rs/iplocate/address")
}

const getFullName = (text: string) => {
  return dadataApi.post<DadataFullName>("/rs/suggest/fio", {
    query: text,
    "parts": ["SURNAME", "NAME", "PATRONYMIC"]
  })
}

const getAddress = (text: string, fieldKey: string, value: {
  "region"?: string,
  "city"?: string,
  "street"?: string,
  "house"?: string,
  "flat"?: string
}) => {
  const hasAnyValue = Boolean(Object.values(value)
    .filter(item => !item)
    .length)

  return dadataApi.post<DadataAddress>("/rs/suggest/address", {
    query: text,
    from_bound: { value: fieldKey },
    to_bound: { value: fieldKey },
    ...(hasAnyValue ? { locations: [value] } : {})
  })
}

const getIssuedBy = (divisionCode: string) => {
  return dadataApi.post<DadataIssuedBy>("/rs/suggest/fms_unit", {
    query: divisionCode
  })
}

export const dadata = {
  getAddressByIp,
  getFullName,
  getAddress,
  getIssuedBy
}
