import { pedantMarketApi } from "../clients"
import type { ModelMemory, ModelMemoryListParams } from "../types"

const getList = (params?: ModelMemoryListParams) => {
  return pedantMarketApi.get<ModelMemory[]>("/model-memories", {
    params: {
      ...params
    }
  })
}

export const modelMemories = {
  getList
}
