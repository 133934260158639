<script setup lang="ts">
import { ref, computed } from "vue"
import { useSeoContentStore } from "stores"
import { sectionStyles } from "shared/lib"
import { Button } from "shared/ui"
import { cx } from "cva"

const rootElement = ref()

const textStyles = cx([
  "relative",
  "max-h-28",
  "overflow-hidden",
  "[&>*]:block",
  "[&>*:nth-child(n+3)]:hidden",
  "xl:[&>*:nth-child(n+3)]:block",
  "mb-2.5",
  "before:absolute",
  "before:bottom-0",
  "before:w-full",
  "before:bg-gradient-to-b",
  "before:from-transparent",
  "before:to-white",
  "before:h-20"
])

const seoContentStore = useSeoContentStore()

const isCollapsed = ref(true)
const seoText = computed(() => {
  return seoContentStore.list.length ? seoContentStore.list[0].Text : ""
})

const scrollTo = () => {
  const header = document.getElementById("header")
  if (!header) return

  const headerHeight = header.offsetHeight
  const topOfElement = window.scrollY + rootElement.value.getBoundingClientRect().top - headerHeight - 20
  window.requestAnimationFrame(() => {
    window.scroll({ top: topOfElement, behavior: "smooth" })
  })
}
</script>

<template>
  <div
    v-if="seoText"
    ref="rootElement"
    :class="[sectionStyles, 'container']"
  >
    <div
      :class="isCollapsed ? ['text-container', textStyles] : ''"
      v-html="seoText"
    />
    <Button
      intent="link"
      underline="on"
      @click.prevent="scrollTo(); isCollapsed = !isCollapsed"
    >
      {{ isCollapsed ? 'Развернуть' : 'Скрыть' }}
    </Button>
  </div>
</template>

<style scoped>
:deep(p) {
  margin-bottom: 16px;
}

:deep(h2) {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  :deep(h2) {
    font-size: 16px;
  }
}

:deep(ul) {
  margin-bottom: 16px;
}

:deep(li) {
  padding-left: 1em;
  text-indent: -.7em;
}

:deep(li::before) {
  content: "• ";
  color: #023E7F;
}
</style>
