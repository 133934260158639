const inner = [
  "grid",
  "grid-cols-[auto_42px]"
]

const input = [
  "bg-bg-main-secondary",
  "border-2",
  "border-solid",
  "border-border-primary",
  "rounded-[12px_0_0_12px]",
  "py-[9px]",
  "px-[13px]",
  "flex-1",
  "w-full",
  "h-full",
  "placeholder:text-ghost",
  "focus:border-2",
  "focus:border-solid",
  "focus:border-border-active",
  "focus:outline-none",
  "focus-visible:border-2",
  "focus-visible:border-solid",
  "focus-visible:border-border-active",
  "focus-visible:outline-none"
]

const inputValid = [
  "border-2",
  "border-solid",
  "border-border-primary",
  "border-r-0",
  "focus:border-2",
  "focus:border-solid",
  "focus:border-border-primary",
  "focus:border-r-0",
  "focus-visible:border-2",
  "focus-visible:border-solid",
  "focus-visible:border-border-primary",
  "focus-visible:border-r-0"
]

const inputInvalid = [
  "!border-2",
  "!border-solid",
  "border-border-error-disable",
  "!border-r-0"
]

const button = [
  "!p-[13px]",
  "rounded-[0_12px_12px_0]",
  "bg-bg-main-primary"
]

const buttonIcon = "text-primary-on-color"

const buttonValid = [
  "!border-2",
  "!border-solid",
  "!border-border-primary",
  "!border-l-0",
  "!bg-bg-main-secondary"
]

const buttonValidIcon = "!text-success"

const buttonInvalid = [
  "!border-2",
  "!border-solid",
  "border-border-error-disable",
  "!border-l-0",
  "!bg-bg-main-secondary"
]

const buttonFocus = "!bg-bg-blue-accent"

const invalidNotice = [
  "text-[11px]",
  "text-error"
]

export default {
  inner,
  input,
  inputValid,
  inputInvalid,
  button,
  buttonIcon,
  buttonValid,
  buttonValidIcon,
  buttonInvalid,
  buttonFocus,
  invalidNotice
}
