import { cx } from "cva"

const wrapper = [
  "fixed",
  "right-[7px]",
  "bottom-[7px]",
  "flex",
  "items-center",
  "bg-social-wa-default",
  "hover:bg-social-wa-hover",
  "active:bg-social-wa-click",
  "z-[90]",
  "text-primary-on-color",
  "rounded-[48px]",
  "text-[12px]/[1.25]",
  "!py-[5px]",
  "!px-[21px]",
  "no-underline",
  "xl:hidden",
  "hover:text-primary-on-color",
  "transition",
  "duration-300"
]

const icon = cx([
  "mr-[5px]",
  "text-icon-primary-on-color"
])

const text = "font-bold"

const weOnline = [
  "flex",
  "items-center"
]

export default {
  wrapper,
  icon,
  text,
  weOnline
}
