<script setup lang="ts">
import { ref, computed, watch } from "vue"
import classes from "./classes"
import { Modal, Button } from "shared/ui"
import { useBreakpoints } from "@vueuse/core"
import { useServiceCentersStore } from "stores"
import { storeToRefs } from "pinia"
import { ServiceCenter } from "shared/api"
import GLightbox from "glightbox"

const breakpoints = useBreakpoints({ xl: 1200 })
const xlAndLarger  = breakpoints.greaterOrEqual("xl")

const serviceCentersStore = useServiceCentersStore()
const { shownInModal: shownServiceCenter } = storeToRefs(serviceCentersStore)

const mapUrl = computed(() => {
  const { latitude, longitude } = <ServiceCenter>shownServiceCenter.value

  return `https://yandex.ru/maps/?rtext=~${latitude},${longitude}`
})

const fullAddress = computed(() => shownServiceCenter.value ?
  `г. ${shownServiceCenter.value.city}, ${shownServiceCenter.value.address}` :
  "")

const activeImageSource = ref("")
const activeImageIndex = ref(0)
const setActiveImage = (source: string, index: number) => {
  activeImageSource.value = source
  activeImageIndex.value = index
}

const selectedImageSource = computed(() => activeImageSource.value || ((<ServiceCenter>shownServiceCenter.value).images?.length && (<ServiceCenter>shownServiceCenter.value).images[0].url))
const lightboxImages = computed(() => {
  if (!shownServiceCenter.value) return

  return shownServiceCenter.value.images.map(image => {
    return {
      "href": image.url
    }
  })
})

const openLightbox = () => {
  const lightbox = GLightbox({
    elements: lightboxImages.value,
    startAt: activeImageIndex.value
  })

  lightbox.on("slide_changed", ({ current }: { current: { slideIndex: number } }) => {
    setActiveImage((<ServiceCenter>shownServiceCenter.value).images[current.slideIndex].url, current.slideIndex)
  })

  lightbox.open()
}

watch(
  () => shownServiceCenter,
  () => {
    activeImageSource.value = ""
  },
  { deep: true }
)
</script>

<template>
  <Modal
    v-if="shownServiceCenter"
    :to-bottom="!xlAndLarger"
    content-transition="vfm-slide-up"
    :title="`Филиал ${shownServiceCenter.anchor}`"
  >
    <div class="xl:max-w-[684px] p-4 xl:p-0 text-center">
      <div class="whitespace-pre-line mb-2">
        <p>{{ fullAddress }}</p>
        <p>{{ shownServiceCenter.work_times }}</p>
      </div>
      <Button
        type="a"
        intent="link"
        underline="on"
        class="justify-center"
        :href="mapUrl"
        target="_blank"
      >
        Показать на карте
      </Button>
      <img
        v-if="selectedImageSource"
        class="rounded-xl mt-6 mb-[22px] cursor-zoom-in"
        :src="selectedImageSource"
        loading="lazy"
        :alt="fullAddress"
        @click="openLightbox()"
      >
      <div
        v-if="shownServiceCenter.images?.length"
        :class="classes.gallery"
      >
        <img
          v-for="(image, index) in shownServiceCenter.images"
          :key="image.id"
          :class="[classes.galleryItem, image.url === selectedImageSource ? classes.activeGalleryItem : '']"
          :src="image.url"
          loading="lazy"
          :alt="fullAddress"
          @click="setActiveImage(image.url, index)"
        >
      </div>
    </div>
  </Modal>
</template>
