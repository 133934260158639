import { acceptHMRUpdate, defineStore } from "pinia"
import { ref } from "vue"
import api, { type Organization } from "shared/api"

export const useOrganizationsStore = defineStore("organizations", () => {
  const list = ref<Organization[]>([])

  function getList() {
    return api.organizations.getList()
      .then(({ data }) => { list.value = data })
      .catch(() => { list.value = [] })
  }

  return {
    list,
    getList
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOrganizationsStore, import.meta.hot))
}
