import { acceptHMRUpdate, defineStore } from "pinia"
import { ref } from "vue"
import api, { type SalesHits } from "shared/api"

export const useSalesHitsStore = defineStore("sales-hits", () => {
  const list = ref<SalesHits[]>([])
  function getList() {
    api.salesHits.getList({ IsSubscriptionOnly: 1 }).then(({ data }) => {
      list.value = data
    }).catch(() => {
      list.value = []
    })
  }
  return {
    list,
    getList
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSalesHitsStore, import.meta.hot))
}
