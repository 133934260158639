<!-- eslint-disable no-unused-vars -->
<script setup lang="ts">
import classes from "./classes"
import { type VariantProps } from "cva"
import { DropdownSelectOption } from "shared/lib"

type WrapperProps = VariantProps<typeof classes.wrapper>;

interface Props {
  modelValue: string,
  options: Array<DropdownSelectOption>,
  intent: WrapperProps["intent"],
}

withDefaults(
  defineProps<Props>(),
  {
    modelValue: "",
    intent: "primary",
    options: () => { return [] }
  }
)

const emit = defineEmits<{
    (event: "update:modelValue", payload: string): void;
}>()
</script>

<template>
  <div :class="classes.wrapper({ intent })">
    <select
      :value="modelValue"
      :class="classes.select({ intent })"
      required
      @change="emit('update:modelValue', ($event.target as HTMLInputElement).value)"
    >
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.name }}
      </option>
    </select>
  </div>
</template>
